<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Rule from "@/components/widgets/queryBuilderRule.vue";
import TargetRule from "../personalizations/targetQueryBuilderRule";
import audienceFields from "./audienceFields.vue";
import targetFields from "../personalizations/targetFields.vue";
import LoyaltyRule from '@/components/loyalty/loyaltyQueryBuilderRule'
import loyaltyFields from '@/components/loyalty/loyaltyFields'

import {


} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      submitted:false,
      showAudienceFields:false,
      isNewGroup: false
    };
  },
  components: { Rule, TargetRule, LoyaltyRule,  audienceFields, targetFields, loyaltyFields},
  mounted() {

    
  },
    methods: {
    
    onSaveAudienceClicked(){
      this.$emit('saveAudience')
    },
    onGroupTypeSelected(type){
      this.query.type = type;
      this.applyRuleExplorer();
    },
    addNode() {
      this.query.children.push({
        field: null,
        operator: null,
        term: null
      });
    },
    showAudienceFieldsModal(isNewGroup){
      if(this.conditionType == 'audienceType'){
        this.showAudienceFields = true
        this.isNewGroup = isNewGroup;
      }else{
        //this.addNode();
        this.showAudienceFields = true
        //this.isNewGroup = isNewGroup;
      }
    },
    addGroup() {
      
        this.query.children.push({
        group: true,
        type: "and",
        children: [
          {
            field: null,
            operator: null,
            term: null
          }
        ]
      });
      
    },
    removeGroupFromItem(item) {
        if(item)
            item.group = 'to_remove';
        
            this.parent.children = this.parent.children.filter(c=>c.group != 'to_remove');
    },

    onRemoveCondition(index){
      this.query.children.splice(index, 1);
    },
    onAudienceFieldSelected(id){
      if(!this.isNewGroup){
        this.query.children.push({
        field: id,
        operator: null,
        term: null,
        times:{},
        new: true
      });
      }else{
        this.query.children.push({
        group: true,
        type: "and",
        children: [
          {
            field: id,
            operator: null,
            term: null,
            times:{},
            new: true
          }
        ]
      });
      }
      
      this.showAudienceFields = false;
    },
    applyRuleExplorer(){
      this.$emit('apply');
    }
  },
  props: {
    query: Object,
    parent: Object,
    index: Number,
    conditionType: String,
    properties_products: Array,
    properties_crm: Array,
    affinity_Types: Array,
    audiences: Array,
    categories: Array,
    rfm_segments: Array,
    custom_interactions: Array,
    campaigns: Array,
    hosts: Array,
    contactLists: Array,
    is_explorer: Boolean,
    memberships: Array,
  },
  name: "ExpressionBuilder",
  watch: {
    query: {
      immediate: true,
      handler(newVal) {
        if(newVal && newVal.group && newVal.type && newVal.type!=''){
          this.onGroupTypeSelected(newVal.type)
        }
      }
    },
    field: {
      immediate: true,
      handler(newVal) {
        this.query.field = newVal;
      }
    },
    terms: {
        immediate: true,
        handler(newVal) {
            this.query.term = newVal;
      }
    },
    custom_interactions:{
      immediate:true,
      handler(newVal){
        if(newVal && newVal.length > 0){
          
          newVal.forEach((c)=>{

            c.parameters?.forEach((pr)=>{
              pr.label = pr.id
            })

            if(c.parameters.filter(p=> p.type == 'item').length > 0){
              this.properties_products.forEach((p)=>{
                //if(c.parameters.filter(pr => pr.id == p.column_name).length == 0)
                if(p.column_name == 'any'){
                  c.parameters.unshift({
                    label:p.name,
                    id: p.column_name,
                    type: p.type
                  })
                }else{
                  c.parameters.push({
                    label:p.name,
                    id: p.column_name,
                    type: p.type
                  })
                }
                  
              })
            }
          });

          if(this.node && this.node.field){
            this.onAudienceFiedSelected(this.node.field,true);
          }
        }
      }
    }
  },
  emits: ['apply', 'saveAudience']
}

 </script>

<template>
    <div class="card mb-0">
        <div class="card-header">
            <div class="d-flex justify-content-between form-inline">
                <div class="btn-group-horizontal" role="group">
                    <button class="btn btn-sm me-1" :class="query.type == 'and' ? 'btn-primary' : 'btn-outline-primary'" @click="onGroupTypeSelected('and')">
                        {{ $t('audiences.audience_conditions_and')  }}
                    </button>
                    <button class="btn btn-sm" :class="query.type == 'or' ? 'btn-primary' : 'btn-outline-primary'" @click="onGroupTypeSelected('or')">
                      {{ $t('audiences.audience_conditions_or')  }}
                    </button>
                </div>
            <div>
              <button type="button" v-on:click="showAudienceFieldsModal(false)" class="btn btn-sm btn-link ms-2 mt-0 mb-0"><i class="bx bx-plus"></i>{{$t("audiences.audience_condition")}}</button>
        </div>
        <button
            type="button"
            v-if="parent != null"
            v-on:click="removeGroupFromItem(query)"
            class="btn btn-sm btn-outline-danger">
            {{ $t('audiences.audience_conditions_remove_group')  }}
        </button>
      </div>
    </div>
    <div v-for="(item, index) in query.children" :key="index" class="card-body pb-0">
      <ExpressionBuilder v-if="item.group" :query="item" :parent="query" :index="index" :conditionType="conditionType" :properties_products="properties_products" :affinity_Types="affinity_Types" :properties_crm="properties_crm" :audiences= "audiences" :categories="categories" :rfm_segments="rfm_segments" :contactLists="contactLists" @saveAudience="saveAudience"/>
      <div v-else>
        <Rule v-if= "conditionType=='audienceType'" :node="item" :index="index" @removeCondition="onRemoveCondition" :properties_products="properties_products" :affinity_Types="affinity_Types" :properties_crm="properties_crm" :categories="categories" :rfm_segments="rfm_segments" :custom_interactions="custom_interactions" :campaigns="campaigns" :hosts="hosts" :contactLists="contactLists" :is_explorer="is_explorer" @applyExplore="applyRuleExplorer" ></Rule>
        <TargetRule v-if= "conditionType=='targetType'"  :node="item" :index="index" @removeCondition="onRemoveCondition" :properties_products="properties_products" :affinity_Types="affinity_Types" :audiences= "audiences" :categories="categories" :custom_interactions="custom_interactions" :contactLists="contactLists"  ></TargetRule>
        <LoyaltyRule v-if= "conditionType=='loyaltyType'" :node="item" :index="index" @removeCondition="onRemoveCondition" :properties_products="properties_products" :affinity_Types="affinity_Types" :properties_crm="properties_crm" :categories="categories" :rfm_segments="rfm_segments" :custom_interactions="custom_interactions" :audiences="audiences" :campaigns="campaigns" :hosts="hosts" :contactLists="contactLists" :memberships="memberships"></LoyaltyRule>
      </div>
    </div>
    <div>
      <button type="button" v-on:click="showAudienceFieldsModal(false)" class="btn btn-sm btn-link ms-2 mt-3 mb-3" v-if="!is_explorer"><i class="bx bx-plus"></i>{{$t("audiences.audience_condition")}}</button>
      <button type="button" v-on:click="onSaveAudienceClicked()" class="btn btn-sm btn-link ms-2 mt-3 mb-3" v-if="is_explorer"><i class="bx bx-save me-1"></i>{{ $t("audience_explorer.save_audience") }}</button>
      <!--<button type="button" v-on:click="showAudienceFieldsModal(true)" class="btn btn-sm btn-link mt-3 mb-3">{{ $t('audiences.audience_conditions_add_group')  }}</button>-->
    </div>
    <b-modal v-model="showAudienceFields" hide-footer :title="$t('audiences.audience_conditions')" v-if="conditionType=='audienceType'">
      <audienceFields @onValueClicked="onAudienceFieldSelected" :custom_interactions="custom_interactions"  />
    </b-modal>

    <b-modal v-model="showAudienceFields" hide-footer :title="$t('audiences.audience_conditions')" v-if="conditionType=='targetType'">
      <targetFields @onValueClicked="onAudienceFieldSelected" :custom_interactions="custom_interactions"  />
    </b-modal>

    <b-modal v-model="showAudienceFields" hide-footer :title="$t('audiences.audience_conditions')" v-if="conditionType=='loyaltyType'">
      <loyaltyFields @onValueClicked="onAudienceFieldSelected" :custom_interactions="custom_interactions"  />
    </b-modal>
  </div>
</template>