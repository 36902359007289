<script>
/**
 * Profile audience Fields
 */
export default {
  components: {
  },
  data() {
    return {

      audienceFields:[
        {
          group: this.$t("audiences.audience_conditions_group_visit_behavior"),
          icon:'mdi mdi-cursor-pointer',
          values:[
            { label: this.$t("audiences.audience_conditions_fields_pages_visited"), value:'pages_viewed'},
            { label: this.$t("audiences.audience_conditions_fields_url_visit"), value:'visit_url'},
            { label: this.$t("audiences.audience_conditions_fields_added_to_cart"), value:'added_to_cart'},
            { label: this.$t("audiences.audience_conditions_fields_added_to_like"), value:'added_to_like'},
            
            //{ label: 'Cantidad de sesiones'},
          ],
        },
        {
          group: this.$t("audiences.audience_conditions_group_purchase_behavior"),
          icon: 'mdi mdi-cart',
          values:[
            { label: this.$t("audiences.audience_conditions_fields_purchases"), value: 'purchases'},
            { label: this.$t("audiences.audience_conditions_fields_purchase_value"), value: 'purchase_value'},
            
            //{ label: 'AOV'},
            //{ label: 'Fecha ultima compra'},
            //{ label: 'Monto ultima compra'},
          ]
        },
        {
          group: this.$t("audiences.audience_conditions_fields_affinity"),
          icon: 'mdi mdi-account-heart',
          value: 'affinity'
        },
        {
          group: this.$t("audiences.audience_conditions_group_predictive"),
          icon:"mdi mdi-crystal-ball",
          values:[
            { label: this.$t("audiences.audience_conditions_fields_affinity"), value: 'affinity'},
            { label: this.$t("audiences.audience_conditions_fields_likehood_purchase"), value: 'likehood_purchase'},
            { label: this.$t("audiences.audience_conditions_fields_customer_cycle_status"), value: 'customer_cycle_status'},
          ]
        },
        {
          group: this.$t("audiences.audience_conditions_fields_customer_properties"),
          icon:"mdi mdi-database",
          value: 'customer_field'
        },
        {
          group: 'Cluster RFM',
          icon:'mdi mdi-account-cash',
          value: 'cluster_rfm'
        },
        {
          group: this.$t("audiences.audience_conditions_group_contact_list"),
          icon:'mdi mdi-book-account-outline',
          value: 'contact_lists'
        },
        {
          group: this.$t("audiences.audience_conditions_group_interactions"),
          icon:'mdi mdi-gesture-tap',
          values:[
            { label: this.$t("audiences.audience_conditions_fields_interaction_purchase"), value: 'interaction_purchase'},
            { label: this.$t("audiences.audience_conditions_fields_interaction_cart"), value: 'interaction_cart'},
            { label: this.$t("audiences.audience_conditions_fields_interaction_like"), value: 'interaction_like'},
            { label: this.$t("audiences.audience_conditions_fields_interaction_view"), value: 'interaction_view'},
          ]
        },
        {
          group: this.$t('audiences.audience_conditions_group_custom_interactions'),
          icon:'mdi mdi-flash-circle',
          id: 'custom_interactions',
          values:[]
        },
        {
          group: "Live Shopping",
          icon:'mdi mdi-camera-front-variant',
          values:[
            { label: this.$t("audiences.audience_conditions_fields_live_Shopping_invitations"), value: "live_shop_invitations_count"},
            { label: this.$t("audiences.audience_conditions_fields_live_Shopping_events"), value: "live_shop_events_count"}
          ]
        },
        {
          group: this.$t('audiences.audience_conditions_group_demographic'),
          icon:'mdi mdi-map-marker-radius',
          values:[
            { label: this.$t("audiences.audience_conditions_fields_country"), value: "country"}
          ]
        },

        /*
        {
          group: 'Interacciones con canales'
        },
        {
          group: 'Otra Audiencia'
        },
        {
          group: 'Búsquedas'
        },
        {
          group: 'Live Shopping'
        },
        {
          group: 'Personalizaciones'
        },*/
      ]

      /* targetFields:[
        {
          group: this.$t("personalizations.experience_fields_group_session_behaviour"),
          values: [
            {
              label: this.$t("personalizations.experience_fields_new_customer"),
              value: "new_customer",
              operator: '=',
              term: {
                component: "boolean",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_authenticated_customer"),
              value: "authenticated_customer",
              operator: '=',
              term: {
                component: "boolean",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_pages_viewed"),
              value: "view_page",
              operator: '>',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_cart_count"),
              value: "cart_count",
              operator: '>',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_current_url"),
              value: "current_url",
              operator: 'like',
              term: {
                component: "text",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_visit_url"),
              value: "visit_url",
              operator: 'like',
              term: {
                component: "text",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_previous_page"),
              value: "previous_page",
              operator: 'like',
              term: {
                component: "text",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_first_page"),
              value: "first_page",
              operator: 'like',
              term: {
                component: "text",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_interaction_view"),
              value: "interaction_view",
              operator: '',
              term: {
                component: "products_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_interaction_cart"),
              value: "interaction_cart",
              operator: '',
              term: {
                component: "products_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_interaction_like"),
              value: "interaction_like",
              operator: '',
              term: {
                component: "products_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_interaction_rate"),
              value: "interaction_rate",
              operator: '',
              term: {
                component: "products_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_interaction_purchase"),
              value: "interaction_purchase",
              operator: '',
              term: {
                component: "products_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_affinity"),
              value: "affinity",
              operator: '',
              operatorsFields: [
                {"label" : this.$t("audiences.audience_conditions_affinity_highest"), "id" : "highest"},
                {"label" : "Top 3", "id" : "top_3"},
                {"label" : "Top 5", "id" : "top_5"},
                {"label" : "Top 10", "id" : "top_10"},
              ],
              term: {
                component: "affinity_page_component",
              }
            },
            
            
          ]
        },
        {
          group: 'Custom Interactions',
          id: 'custom_interactions',
          values: []
        },
        { group: this.$t("personalizations.experience_fields_group_customers"),
          values: [
            {
              label: this.$t("personalizations.experience_fields_audience"),
              value: "audience",
              operator: 'in',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
                {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
              ],
              term: {
                component: "audiences_component",
              }
            },
          ]
        },
        {
          group: this.$t("audiences.audience_conditions_group_demographic"),
          values: [
            {
              label: this.$t("audiences.audience_conditions_fields_country"),
              value: "country",
              operator: 'in',
              term: {
                component: "country",
              }
            },
            {
              label:this.$t("personalizations.experience_fields_device"),
              value: "device",
              operator: '=',
              term: {
                component: "multiselect",
                values:[
                {
                  id:0,
                  label: "Mobile"
                },
                {
                  id:1,
                  label: "Desktop"
                }
                ]
              }
            },
            {
              label:this.$t("personalizations.experience_fields_weather"),
              value: "weather",
              operator: '=',
              term: {
                component: "multiselect",
                values:[
                  {
                    id:0,
                    label: "rain"
                  },
                  {
                    id:1,
                    label: "clear"
                  },
                  {
                    id:3,
                    label: "clouds"
                  },
                  {
                    id:4,
                    label: "thunderstorm"
                  },
                  {
                    id:5,
                    label: "snow"
                  },
                  {
                    id:6,
                    label: "drizzle"
                  },
                  {
                    id:7,
                    label: "mist"
                  },
                  {
                    id:8,
                    label: "haze"
                  },
                  {
                    id:9,
                    label: "windy"
                  },
                  {
                    id:10,
                    label: "sunny"
                  },
                  {
                    id:11,
                    label: "partly_cloudy"
                  },
                  {
                    id:12,
                    label: "overcast"
                  }
                ]
              }
            },
          ]
        },
        {
              group: this.$t("personalizations.experience_fields_group_time"),
              values: [
              {
                label: this.$t("personalizations.experience_fields_dates"),
                value: "dates",
                operator: 'in',
                operators: [
                  {"label" : this.$t("personalizations.experience_fields_before") , "id" : "<"},
                  {"label" :this.$t("personalizations.experience_fields_after") , "id" : ">"},
                  {"label" : this.$t("personalizations.experience_fields_equal_date") , "id" : "="},
                ],
                term: {
                  component: "dates_rage",
                }
              },
              {
                label: this.$t("personalizations.experience_fields_days"),
                value: "days",
                operator: 'in',
                term: {
                  component: "multiselect",
                  values: [
                    {
                      id:0,
                      label: this.$t("availability.availability_days_monday")
                    },
                    {
                      id:1,
                      label: this.$t("availability.availability_days_tuesday")
                    },
                    {
                      id:2,
                      label: this.$t("availability.availability_days_wednesday")
                    },
                    {
                      id:3,
                      label: this.$t("availability.availability_days_thursday")
                    },
                    {
                      id:4,
                      label: this.$t("availability.availability_days_friday")
                    },
                    {
                      id:5,
                      label: this.$t("availability.availability_days_saturday")
                    },
                    {
                      id:6,
                      label: this.$t("availability.availability_days_sunday")
                    },
                  ]
                }
              },
              {
                label:this.$t("personalizations.experience_fields_hours"),
                value: "hours",
                operator: 'in',
                term: {
                  component: "multiselect",
                  values: [
                    {
                      id:0,
                      label: this.$t("personalizations.experience_fields_hours_morning")
                    },
                    {
                      id:1,
                      label: this.$t("personalizations.experience_fields_hours_afternoon")
                    },
                    {
                      id:2,
                      label: this.$t("personalizations.experience_fields_hours_night")
                    },
                  ]
                }
              }
              ]
            }
      ]*/,
      isLocal: window.config.local ? true : false
    };
  },
  mounted() {
    if(this.isLocal){
      this.audienceFields.push({
        group: 'Channels reachability',
        icon:"mdi mdi-crystal-ball",
        value: "channel_reachability"
      });

      this.audienceFields.push({
        group: 'Channels Engagement',
        icon:"mdi mdi-crystal-ball",
        value: "channel_engagement"
      })
    }
  },
  props: {
    custom_interactions:Array
  },
  emits:['onValueClicked'],
  methods: {
    onGroupClicked(group){
      if(!group.values){
        this.$emit('onValueClicked',group.value);
      }
    },
    onValueSelected(value){
      this.$emit('onValueClicked',value.value);
    }
  },
  watch: {
    custom_interactions: {
      immediate:true,
      handler(newVal){
        if(newVal && newVal.length > 0){
          const field =this.audienceFields.filter(a=>a.id == 'custom_interactions')[0]
          newVal.forEach((c)=>{
            field.values.push(
              { label: c.name, value:c.key},
            )
          })
        }
      }
    }
  }
};
</script>
<template>
  <div>
    <p class="small">{{ $t('audiences.audience_conditions_subtitle') }}</p>
    <div v-for="(field, index) in audienceFields" :key="index" class="mb-2">
      <div v-b-toggle="'accordion-' + index" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 38px;" role="tab" v-on:click="onGroupClicked(field)">
        <button class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto"><div class="text-primary"><i :class="field.icon"></i> {{ `${field.group}` }}</div></button>
      </div>
      <b-collapse :id="`accordion-${index}`" role="tabpanel" class="mt-2" accordion="my-accordion">
        <ul>
          <li class="dropdown-item py-1 ps-0 interact" v-for="o in field.values" :key="o.label" v-on:click="onValueSelected(o)"> {{ o.label }}</li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>
