<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";

import {


} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
        submitted:false,
        products:[],
        aux_times: {
          operator: '>'
        },
        operators:[
          /*{"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
          {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
          {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="}, */

          {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
          {"label" : this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
          {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
        ],

        operatorsPrice:[
          {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
          {"label" :this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
          {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
        ],
        booleanValues: [
          {
            id: true,
            label: this.$t("common.yes"),
          },
          {
            id: false,
            label: this.$t("common.no"),
          },
        ],
        numberOperatorValues: [
          {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_greated_than'), "id" : ">"},
          {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_lower_than'), "id" : "<"},
          {"label" : this.$t('journeys.journey_workflow_components_wait_property_wait_condition_equal'), "id" : "="},
        ],
        dateOperatorsValues: [
        {
          id: "<",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_before_day'),
        },
        {
          id: ">",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_after_day'),
        },
        {
          id: "=",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_equal'),
        },
      ],

      invitationsRules : [
        { id: 'any', label: this.$t('audiences.audience_conditions_fields_live_Shopping_invitations_any'), type: 'any'},
        { id: 'invitation_date', label: this.$t('audiences.audience_conditions_fields_live_Shopping_invitations_date'), type: 'date'},
        { id: 'invitation_campaign', label: this.$t('audiences.audience_conditions_fields_live_Shopping_invitations_campaign'), type: 'campaigns'},
      ],

      eventsRules : [
        { id: 'any', label: this.$t('audiences.audience_conditions_fields_live_Shopping_invitations_any'), tpe: 'any'},
        { id: 'event_date', label: this.$t('audiences.audience_conditions_fields_live_Shopping_events_date'), type: 'date'},
        { id: 'event_campaign', label: this.$t('audiences.audience_conditions_fields_live_Shopping_invitations_campaign'), type: 'campaigns'},
        { id: 'event_host', label: this.$t('audiences.audience_conditions_fields_live_Shopping_events_host'), type: 'hosts'},
        { id: 'event_status', label: this.$t('audiences.audience_conditions_fields_live_Shopping_events_status'), type: 'eventStatus'},
      ],

      eventStatus : [
        { id: 'scheduled', label: this.$t('events_list.status_scheduled')},
        { id: 'closed', label: this.$t('events_list.status_closed')},
        { id: 'started', label: this.$t('events_list.status_started')},
        { id: 'missed', label: this.$t('events_list.status_missed')},
        { id: 'canceled', label: this.$t('events_list.status_canceled')},
      ],

      


      };
  },
  components: { Multiselect},
	name: "LiveShoppingExpressionBuilder",
  mounted() {
  },
    methods: {
        LiveShoppingFieldSelected(item){
          item.term = null;
          item.operator = this.operatorFromItem(item);
			},

      operatorFromItem(item){
        if(item && item.field){
          switch (item.field.type){
            case 'campaigns':
              return 'in';
            case 'hosts':
              return 'in';
            case 'eventStatus':
              return 'in';
            case 'date':
              return '>';
            case 'product_price':
              return '>';
            case 'boolean':
              return '=';
            case 'product_category':
              return 'in';
            default:
              return '' 
          }
        }
        return '';
      },
			isNodeValid(){
				return this.node.field && this.node.operator && this.node.term;
			},
			onRemoveCondition(index){
				this.query.children.splice(index, 1);
			},
      onAddCondition(){
        this.query.children.push({
          field: null,
          operator:null,
          term: null
        })
      },
      onTimesOperatorSelected(time, isUpdate = false){
        if(!isUpdate){
          this.times.operator = time.id;
          this.aux_times.operator = time;
        }
        else{
          this.aux_times.operator = this.operators.filter(o=> o.id == time).length > 0 ? this.operators.filter(o=> o.id == time)[0] : null
        }
      },

      onOperatorSelected(item, isUpdate = false){
        if(!isUpdate){
          item.operator = item.aux_operator?.id;
        }else{
          this.item.aux_operator = this.operatorsPrice.filter(o=> o.id == item).length > 0 ? this.operatorsPrice.filter(o=> o.id == item)[0] : null ;
        }
      },
      
    },
  props: {
    query: Object,
    times: Object,
    campaigns: Array,
    hosts: Array,
    hideTimes:Boolean,
    isInvitation: Boolean
  },
	emits:["removeCondition"],
  
  watch: {
    times:{
      immediate: true,
      handler(newVal){
        if(newVal){
          this.onTimesOperatorSelected(newVal.operator,true);
        }
      }
    },
    query:{
      immediate: true,
      deep: true,
      handler(newVal){
        if(newVal){
          if(newVal?.children?.length > 0){
            newVal?.children.forEach(c=>{
              if (typeof(c.field) === typeof("any")){
                if(this.isInvitation){
                  c.field = this.invitationsRules.filter(i=> i.id == c.field)[0]
                }else{
                  c.field = this.eventsRules.filter(i=> i.id == c.field)[0]
                }
                  
              }
            })
          }
        }
      }
    },
  }
}

 </script>

<template>
  <div class="row mb-2 ps-0 pe-0">
    <div class="col-sm-10 mb-2 pe-0">
      <div class="btn-group-horizontal pt-2" role="group">
          <button class="btn btn-sm me-1" :class="query.type == 'and'? 'btn-primary' : 'btn-outline-primary'" @click="query.type= 'and'">
              {{ $t("audiences.audience_conditions_and") }}
          </button>
          <button class="btn btn-sm" :class="query.type == 'or'? 'btn-primary' : 'btn-outline-primary'" @click="query.type= 'or'">
            {{ $t("audiences.audience_conditions_or") }}
          </button>
      </div>
    </div>
    <div class="col-sm-2 pt-2 text-end interact">
      <button class="btn btn-sm btn-link" @click="onAddCondition">
      <i class="bx bx-plus"></i>{{$t("audiences.audience_condition")}}</button>
    </div>
    
		<div v-for="(item, index) in query.children" :key="index" class="pb-2">
      <div class="row">
        <div class="col-sm-3">
          <multiselect
              :options="invitationsRules"
              v-model="item.field"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :allow-empty="false"
              :show-labels="false"
              @input="LiveShoppingFieldSelected(item)"
              v-if="isInvitation"
          />
          <multiselect
              :options="eventsRules"
              v-model="item.field"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :allow-empty="false"
              :show-labels="false"
              @input="LiveShoppingFieldSelected(item)"
              v-if="!isInvitation"
          />
        </div>
        <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'list'">
          <multiselect
              :options="item.field.values" 
              v-model="item.term"
              track-by="id"
              label="value"
              selectLabel=""
              deselectLabel=""
              size="sm"
              :multiple="true"
          />
        </div>
          <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'text'">
            <b-form-input class="form-control w-50 me-2" v-model="item.term">
            </b-form-input>
          </div>
          <div v-if="item.field && item.field.type == 'boolean'" class="col-sm-8 ps-0">
              <multiselect
              :options="booleanValues" 
              v-model="item.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control w-50"/>
          </div>
          <div v-if="item.field && item.field.type == 'number'" class="col-sm-8 ps-0">
              <div class="d-inline-flex w-100">
                  <div>
                    <div class="d-inline-flex align-items-baseline">
                      <multiselect
                          :options="numberOperatorValues" 
                          v-model="item.operator"
                          track-by="id"
                          label="label"
                          selectLabel=""
                          deselectLabel=""
                          :multiple="false"
                          :show-labels="false"
                          :allow-empty="false"
                          class="me-2 w-50"
                          />
                        <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="item.term" ></b-form-input> <p class="small"></p>
                    </div>
                  </div>
              </div>
          </div>

          <div v-if="item.field && item.field.type == 'date'" class="col-sm-8 ps-0">
            <div class="d-inline-flex align-items-baseline w-100">
              <multiselect
                :options="dateOperatorsValues" 
                v-model="item.operator"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                :show-labels="false"
                :allow-empty="false"
                class="w-50 me-2"
              />
              <b-form-input type="date" class="form-control" v-model="item.term" ></b-form-input>
            </div>
          </div>
          
          <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'campaigns'">
            <multiselect
              :options="campaigns" 
              v-model="item.term"
              track-by="_id"
              label="name"
              selectLabel=""
              deselectLabel=""
              size="sm"
              :multiple="true"
          />
          </div>
          <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'hosts'">
            <multiselect
              :options="hosts" 
              v-model="item.term"
              track-by="_id"
              label="name"
              selectLabel=""
              deselectLabel=""
              size="sm"
              :multiple="true"
          />
          </div>
          <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'eventStatus'">
            <multiselect
              :options="eventStatus" 
              v-model="item.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              size="sm"
              :multiple="true"
          />
          </div>
          <div class="col-sm-8" v-if="item.field && item.field.type== 'any'">
          </div>
          <div class="col-sm-1 text-end interact pt-2 ms-auto" v-if="item.field && query.children?.length > 1" v-on:click="onRemoveCondition(index)">
            <i class="bx bx-trash font-size-18 text-danger"></i>
          </div>
      </div>
    </div>
    <div class="row pt-0 pe-0" v-if="!hideTimes">
      <div class="col-sm-12 pe-0">
        <hr class="mt-2"/>
        <div class="d-inline-flex align-items-baseline w-100">
          <div class="w-25 me-2">
            <div>
              <multiselect
                :options="operators || []" 
                v-model="aux_times.operator"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                :show-labels="false"
                :allow-empty="false"
                class="form control w-100 ms-0"
                @input="onTimesOperatorSelected"
                />
            </div>
          </div>
          <div class="w-25 me-2">
            <div class="d-inline-flex align-items-baseline">
              <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="times.value" ></b-form-input> <p class="small"> </p>
            </div>
          </div>
        </div>
      </div>
    </div>
	</div>
</template>

<style>
.interact {
  cursor: pointer;
}
</style>