<script>
/**
 * Profile audience Fields
 */
export default {
  components: {
  },
  data() {
    return {

      targetFields:[
        {
          group: this.$t("personalizations.experience_fields_group_session_behaviour"),
          icon:'mdi mdi-cursor-pointer',
          values:[
            { label: this.$t("personalizations.experience_fields_new_customer"), value: "new_customer"},
            { label: this.$t("personalizations.experience_fields_authenticated_customer"), value: "authenticated_customer"},
            { label: this.$t("personalizations.experience_fields_pages_viewed"), value: "view_page"},
            { label: this.$t("personalizations.experience_fields_current_url"), value: "current_url"},
            { label: this.$t("personalizations.experience_fields_previous_page"), value: "previous_page"},
            { label: this.$t("personalizations.experience_fields_first_page"), value: "first_page"},
            { label: this.$t("personalizations.experience_fields_visit_url"), value: "visit_url"},
            { label: this.$t("personalizations.experience_fields_cart_count"), value: "cart_count",}
            
          ],
        },
        {
          group: this.$t('personalizations.experience_fields_group_session_purchase_behaviour'),
          icon:'mdi mdi-cart',
          values:[
            { label: this.$t("audiences.audience_conditions_fields_purchases"), value: 'purchases'},
            { label: this.$t("audiences.audience_conditions_fields_purchase_value"), value: 'purchase_value'},
            { label: this.$t("audiences.audience_conditions_fields_interaction_purchase"), value: 'interaction_purchase'},
          ],
        },
        {
          group: this.$t('personalizations.experience_fields_group_session_interactions_behaviour'),
          icon:'mdi mdi-gesture-tap',
          values:[
            { label: this.$t("audiences.audience_conditions_fields_interaction_purchase"), value: 'interaction_purchase'},
            { label: this.$t("audiences.audience_conditions_fields_interaction_cart"), value: 'interaction_cart'},
            { label: this.$t("audiences.audience_conditions_fields_interaction_like"), value: 'interaction_like'},
            { label: this.$t("audiences.audience_conditions_fields_interaction_view"), value: 'interaction_view'},
          ],
        },
        {
          group: this.$t("audiences.audience_conditions_group_contact_list"),
          icon:'mdi mdi-book-account-outline',
          value: 'contact_lists'
        },
        {
          group:  this.$t('personalizations.experience_fields_group_session_custom_interactions_behaviour'),
          icon:'mdi mdi-flash-circle',
          id: 'custom_interactions',
          values:[],
        },
        {
          group: this.$t('personalizations.experience_fields_group_session_affinity'),
          icon:'mdi mdi-account-heart',
          value: 'affinity'
        },
        {
          group: this.$t("personalizations.experience_fields_audience"),
          icon:'mdi mdi-account-group',
          value: 'audience'
        },
        {
          group: this.$t('audiences.audience_conditions_group_demographic'),
          icon:'mdi mdi-map-marker-radius',
          values:[
            { label: this.$t("audiences.audience_conditions_fields_country"), value: "country"}
          ]
        },
        {
          group: this.$t("personalizations.experience_fields_weather"),
          icon:'mdi mdi-weather-cloudy',
          value: 'weather'
        },
        {
          group: this.$t("personalizations.experience_fields_device"),
          icon:'mdi mdi-devices',
          value: 'device'
        },
        {
          group: this.$t("personalizations.experience_fields_group_time"),
          icon:'mdi mdi-calendar',
          values:[
            { label: this.$t("personalizations.experience_fields_dates"), value: "dates"},
            { label: this.$t("personalizations.experience_fields_days"), value: "days"},
            { label: this.$t("personalizations.experience_fields_hours"), value: "hours"}
          ]
        },
        
      ]
    };
  },
  mounted() {},
  props: {
    custom_interactions:Array
  },
  emits:['onValueClicked'],
  methods: {
    onGroupClicked(group){
      if(!group.values){
        this.$emit('onValueClicked',group.value);
      }
    },
    onValueSelected(value){
      this.$emit('onValueClicked',value.value);
    }
  },
  watch: {
    custom_interactions: {
      immediate:true,
      handler(newVal){
        if(newVal && newVal.length > 0){
          const field =this.targetFields.filter(a=>a.id == 'custom_interactions')[0]
          newVal.forEach((c)=>{
            field.values.push(
              { label: c.name, value:c.key},
            )
          })
        }
      }
    }
  }
};
</script>
<template>
  <div>
    <p class="small">{{ $t('personalizations.experience_fields_subtitle') }}</p>
    <div v-for="(field, index) in targetFields" :key="index" class="mb-2">
      <div v-b-toggle="'accordion-' + index" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 38px;" role="tab" v-on:click="onGroupClicked(field)">
        <button class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto"><div class="text-primary"><i :class="field.icon"></i> {{ `${field.group}` }}</div></button>
      </div>
      <b-collapse :id="`accordion-${index}`" role="tabpanel" class="mt-2" accordion="my-accordion">
        <ul>
          <li class="dropdown-item py-1 ps-0 interact" v-for="o in field.values" :key="o.label" v-on:click="onValueSelected(o)"> {{ o.label }}</li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>
