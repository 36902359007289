<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";
import ProductQueryBuilderRule from "../widgets/queryBuilderProductsRule.vue"
import InteractionQueryBuilderRule from "../widgets/queryBuilderInteractionsRule.vue"

import {
  customerMethods
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      submitted:false,
      booleanValues: [
        {
          id: true,
          label: this.$t("common.yes"),
        },
        {
          id: false,
          label: this.$t("common.no"),
        },
      ],
      targetFields:[
        {
          group: this.$t("personalizations.experience_fields_group_session_behaviour"),
          values: [
            {
              label: this.$t("personalizations.experience_fields_new_customer"),
              value: "new_customer",
              operator: '=',
              term: {
                component: "boolean",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_authenticated_customer"),
              value: "authenticated_customer",
              operator: '=',
              term: {
                component: "boolean",
              }
            },
            /*{
              label: this.$t("personalizations.experience_fields_cookie"),
              value: "cookie",
              operator: 'like',
              term: {
                component: "text",
              }
            },*/
            {
              label: this.$t("personalizations.experience_fields_pages_viewed"),
              value: "view_page",
              operator: '>',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "view_page_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_current_url"),
              value: "current_url",
              operator: 'like',
              term: {
                component: "text",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_previous_page"),
              value: "previous_page",
              operator: 'like',
              term: {
                component: "text",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_first_page"),
              value: "first_page",
              operator: 'like',
              term: {
                component: "text",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_visit_url"),
              value: "visit_url",
              operator: 'like',
              term: {
                component: "text",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_cart_count"),
              value: "cart_count",
              operator: '>',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num",
              }
            },
          ]
        },
        {
          group: this.$t('personalizations.experience_fields_group_session_purchase_behaviour'),
          values:[
          {
              label: this.$t("audiences.audience_conditions_fields_purchase_value"),
              value: "purchase_value",
              operator: '>' ,
              operatorsFields: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "purchase_value",
                value: 'purchase_value',
							}
            },
            {
              label: this.$t("audiences.audience_conditions_fields_purchases"),
              value: "purchases",
              operator: '',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num",
              }
            },
          ]
        },
        {
          group: this.$t('personalizations.experience_fields_group_session_interactions_behaviour'),
          values:[
          {
              label: this.$t("personalizations.experience_fields_interaction_view"),
              value: "interaction_view",
              operator: '',
              term: {
                component: "products_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_interaction_cart"),
              value: "interaction_cart",
              operator: '',
              term: {
                component: "products_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_interaction_like"),
              value: "interaction_like",
              operator: '',
              term: {
                component: "products_component",
              }
            },
            {
              label: this.$t("personalizations.experience_fields_interaction_purchase"),
              value: "interaction_purchase",
              operator: '',
              term: {
                component: "products_component",
              }
            },
          ]
        },
        {
          group: this.$t('personalizations.experience_fields_group_session_custom_interactions_behaviour'),
          id: 'custom_interactions',
          values: []
        },
        {
          group: this.$t('personalizations.experience_fields_group_session_affinity'),
          values:[
          {
              label: this.$t("audiences.audience_conditions_fields_affinity"),
              value: "affinity",
              operator: '',
              operatorsFields: [
                {"label" : this.$t("audiences.audience_conditions_affinity_highest"), "id" : "highest"},
                {"label" : "Top 3", "id" : "top_3"},
                {"label" : "Top 5", "id" : "top_5"},
                {"label" : "Top 10", "id" : "top_10"},
              ],
              term: {
                component: "affinity_page_component",
              }
            },
          ]
        },
        { group: this.$t("personalizations.experience_fields_group_customers"),
          values: [
            {
              label: this.$t("personalizations.experience_fields_audience"),
              value: "audience",
              operator: 'in',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
                {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
              ],
              term: {
                component: "audiences_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_group_contact_list"),
              value: "contact_lists",
              operator: 'in',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
                {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
              ],
              term: {
                component: "contact_list_component",
              }
            },
          ]
        },
        {
          group: this.$t("audiences.audience_conditions_group_demographic"),
          values: [
            {
              label: this.$t("audiences.audience_conditions_fields_country"),
              value: "country",
              operator: 'in',
              term: {
                component: "country",
              }
            },
          ]
        },
        {
          group: this.$t("personalizations.experience_fields_weather"),
          values:[
          {
              label:this.$t("personalizations.experience_fields_weather"),
              value: "weather",
              operator: '=',
              term: {
                component: "multiselect",
                values:[
                  {
                    id:0,
                    label: "rain"
                  },
                  {
                    id:1,
                    label: "clear"
                  },
                  {
                    id:3,
                    label: "clouds"
                  },
                  {
                    id:4,
                    label: "thunderstorm"
                  },
                  {
                    id:5,
                    label: "snow"
                  },
                  {
                    id:6,
                    label: "drizzle"
                  },
                  {
                    id:7,
                    label: "mist"
                  },
                  {
                    id:8,
                    label: "haze"
                  },
                  {
                    id:9,
                    label: "windy"
                  },
                  {
                    id:10,
                    label: "sunny"
                  },
                  {
                    id:11,
                    label: "partly_cloudy"
                  },
                  {
                    id:12,
                    label: "overcast"
                  }
                ]
              }
            },
          ]
        },
        {
          group: this.$t("personalizations.experience_fields_device"),
          values: [
          {
              label:this.$t("personalizations.experience_fields_device"),
              value: "device",
              operator: '=',
              term: {
                component: "multiselect",
                values:[
                {
                  id:0,
                  label: "Mobile"
                },
                {
                  id:1,
                  label: "Desktop"
                }
                ]
              }
            },
          ]
        },
        {
              group: this.$t("personalizations.experience_fields_group_time"),
              values: [
              {
                label: this.$t("personalizations.experience_fields_dates"),
                value: "dates",
                operator: 'in',
                operators: [
                  {"label" : this.$t("personalizations.experience_fields_before") , "id" : "<"},
                  {"label" :this.$t("personalizations.experience_fields_after") , "id" : ">"},
                  {"label" : this.$t("personalizations.experience_fields_equal_date") , "id" : "="},
                ],
                term: {
                  component: "dates_rage",
                }
              },
              {
                label: this.$t("personalizations.experience_fields_days"),
                value: "days",
                operator: 'in',
                term: {
                  component: "multiselect",
                  values: [
                    {
                      id:0,
                      label: this.$t("availability.availability_days_monday")
                    },
                    {
                      id:1,
                      label: this.$t("availability.availability_days_tuesday")
                    },
                    {
                      id:2,
                      label: this.$t("availability.availability_days_wednesday")
                    },
                    {
                      id:3,
                      label: this.$t("availability.availability_days_thursday")
                    },
                    {
                      id:4,
                      label: this.$t("availability.availability_days_friday")
                    },
                    {
                      id:5,
                      label: this.$t("availability.availability_days_saturday")
                    },
                    {
                      id:6,
                      label: this.$t("availability.availability_days_sunday")
                    },
                  ]
                }
              },
              {
                label:this.$t("personalizations.experience_fields_hours"),
                value: "hours",
                operator: 'in',
                term: {
                  component: "multiselect",
                  values: [
                    {
                      id:0,
                      label: this.$t("personalizations.experience_fields_hours_morning")
                    },
                    {
                      id:1,
                      label: this.$t("personalizations.experience_fields_hours_afternoon")
                    },
                    {
                      id:2,
                      label: this.$t("personalizations.experience_fields_hours_night")
                    },
                  ]
                }
              }
              ]
            }
      ],
			selectedField: null,
      operators:[],
      page_Types: [
        {id: "product_detail", label: this.$t("personalizations.page_type_product_detail")},
        {id: "cart", label: this.$t("personalizations.page_type_cart")},
        {id: "checkout", label: 'Checkout'},
        {id: "category", label: this.$t("personalizations.page_type_category") },
        {id: "home", label: this.$t("personalizations.page_type_home") },
        {id: "product_listing", label: this.$t("personalizations.page_type_product_listing") },
        {id: "any", label: this.$t("personalizations.page_type_any") }
      ],
      aux_times: {
        operator: '>'
      },
      aux_node: {
        operator: ''
      },
      countries:[]
    };
  },
  components: { Multiselect, ProductQueryBuilderRule, InteractionQueryBuilderRule },
  mounted() {},
    methods: {
      ...customerMethods,

        getTargetFieldByValue(value){
          
          let selectedField = null

          this.targetFields.forEach((a)=>{
              if(a.values && a.values.length > 0){
                a.values.forEach((v)=>{
                  if(v.value == value){
                    selectedField = v;
                  }
                })
              }
          })

          return selectedField
        },

        loadCountries(){
          //const loader = this.$loading.show();
          
          this.getCustomersCountries().then((countries)=>{
            this.countries = countries;
          }).catch((ex)=>{
            // eslint-disable-next-line no-console
            console.log(ex);
          }).finally(()=>{
            //loader.hide();
          })
        },

        onTargetFiedSelected(type, isUpdate=false){
          if(isUpdate){
            type = this.getTargetFieldByValue(type); 
            this.aux_node.field = type;  
          }

          if(!type)
          return;

          this.operators= type.operators || [];
          
					this.selectedField = type;
          this.node.field = type.value;

          if(type.value == 'country'){
            this.loadCountries();
          }

          if(!isUpdate)
            this.node.operator = type.operator;
          
          if(this.selectedField.term.component == 'products_component' || this.selectedField.term.component == 'custom_interaction_component' ){
            if(!isUpdate || this.node.new){
                this.node.term= {
                type:"and",
                children: [
                  {
                    field: null,
                    operator: null,
                    term: null
                  }
                ]
              },
              this.node.times = {
                operator: '>',
                value: 1
              }
            }
        }else{
          if(!isUpdate){
            this.node.term = '',
            this.node.times = type.times
          }
        }
      },
			isNodeValid(){
				return this.node.field && this.node.operator && this.node.term;
			},
			onRemoveCondition(){
				this.$emit('removeCondition',this.index);
			},
      onAudienceSelected(node, isUpdate = false){
        if(!isUpdate){
          node.operator = null;
          node.term = null
        }
      },
      onTimesOperatorSelected(time, isUpdate=false){
        if(!isUpdate){
          this.node.times.operator = time.id;
        }else{
          this.aux_times.operator = this.selectedField?.operators?.filter(o=> o.id== time.operator).length > 0 ? this.selectedField?.operators?.filter(o=> o.id== time.operator) [0] : null ;
        }
      },
      onAffinityTypeSelected(node, isUpdate = false){
        if(!isUpdate){
          node.operator = null;
          node.term = null
        }
      },
      onAffinityOperatorSelected(operator, isUpdate=false){
        if(!isUpdate){
          this.node.operator = operator.id;
        }else{
          this.aux_node.operator = this.selectedField.operatorsFields.filter(o=> o.id== operator).length > 0 ? this.selectedField.operatorsFields.filter(o=> o.id== operator) [0] :null ;
        }
      },
      onListOperatorSelected(operator,isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.operator?.id;
      }else{
        this.aux_node.operator = this.selectedField.operators.filter(o=> o.id== operator).length > 0 ? this.selectedField.operators.filter(o=> o.id== operator)[0] : null ;
      }
    },
    },
  props: {
    node: Object,
    index: Number,
    properties_products: Array,
    properties_crm: Array,
    affinity_Types: Array,
    audiences: Array,
    categories: Array,
    custom_interactions: Array,
    contactLists: Array    
  },
	emits:["removeCondition"],
  watch: {
    node:{
      immediate: true,
      handler(newVal){
        if(newVal && newVal.field){
          this.onTargetFiedSelected(newVal.field,true);
          
          if(newVal.times){
            this.onTimesOperatorSelected(newVal.times,true);
          }
          
          if(newVal.affinity_type){
            this.onAffinityTypeSelected(newVal.affinity_type,true);
            this.onAffinityOperatorSelected(newVal.operator, true);
          }
          if(newVal.operator == 'in' || newVal.operator == 'not_in' ){
            this.onListOperatorSelected(newVal.operator,true);
          }
        }
      }
    },
    custom_interactions:{
      immediate:true,
      handler(newVal){
        
        if(newVal && newVal.length > 0){
          const field =this.targetFields.filter(a=>a.id=='custom_interactions')
          newVal.forEach((c)=>{
            field[0].values.push({
              label: c.name,
              value: c.key,
              parameters: c.parameters,
              term: {
                component: "custom_interaction_component",
              }
            })
          });

          if(this.node && this.node.field){
            this.onTargetFiedSelected(this.node.field,true);
          }
        }
      }
    }
  }
}

 </script>

<template>
    
	<div class="row">
				<div class="col-sm-3 me-0">
						<multiselect
						:options="targetFields"
						group-values="values"
						group-label="group"
						track-by="value"
						label="label"
						v-model="aux_node.field"
						selectLabel=""
						deselectLabel=""
            :placeholder="$t('audiences.audience_conditions_field_placeholder')"
						@select="onTargetFiedSelected"
            :allow-empty="false"
            :show-labels="false"
						/>
				</div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'boolean'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
              :options="booleanValues" 
              v-model="node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              :allowEmpty="false"
              class="form control form-control-sm w-50"/>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'text'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex">
            <b-form-input type="text" class="form-control w-50 me-2" min="1" v-model="node.term" placeholder="Inlcuye"  ></b-form-input>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'products_component'" class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
					<ProductQueryBuilderRule :query="node.term" :properties_products="properties_products" :times="node.times" :categories="categories" :session="true"/> 
				</div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'custom_interaction_component'" class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
					<InteractionQueryBuilderRule :query="node.term" :interaction_parameters="selectedField.parameters || []" :times="node.times" :session="true" /> 
				</div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'audiences_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex">
            <div class="w-25">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="node.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allowEmpty="false"
                    class="form control form-control-sm w-100"/>
                </div>
              </div>
              <multiselect
                :options="audiences" 
                v-model="node.term"
                track-by="_id"
                label="name"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                class="form control form-control-sm w-100 ms-2"
              />
          </div>
				</div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'multiselect'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
              :options="selectedField.term.values || []" 
              v-model="node.term"
              selectLabel=""
              track-by="id"
              label="label"
              deselectLabel=""
              :multiple="true"
              class="form control form-control-sm w-50"
            />
        </div>
        <div v-if="node.field && selectedField?.term?.component == 'input_num'" class="col-sm-8 d-inline-flex row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
					<div class="w-50 me-2">
              <div>
                <multiselect
                  :options="selectedField?.operators || []" 
                  v-model="aux_times.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  class="form control w-100 ms-0"
                  @input="onTimesOperatorSelected"
                  />
              </div>
            </div>
            <div class="w-25 me-2">
              <div class="d-inline-flex align-items-baseline">
                <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small"> {{node.times.value == 1 ? $t("personalizations.experience_targeting_session_time") :  $t("personalizations.experience_targeting_session_times")}}</p>
              </div>
            </div>
        </div>
        <div v-if="node.field && selectedField?.term?.component == 'country'" class="col-sm-8 d-inline-flex row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
            :options="countries"
            v-model="node.term"
            track-by="id"
            label="name"
            selectLabel=""
            deselectLabel=""
            :multiple="true"
            :placeholder="$t('audiences.audience_conditions_field_country_placeholder')"
            class="form control form-control-sm w-50"/>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'affinity_page_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex">
            <multiselect
              :options="affinity_Types" 
              v-model="node.affinity_type"
              track-by="column_name"
              label="name"
              selectLabel=""
              deselectLabel=""
              class="form control form-control-sm w-25"
              @input="onAffinityTypeSelected(node)"
              />
              <div v-if="node.affinity_type && node.affinity_type.type != 'product_category'" class="w-50 me-4">
                <multiselect
                :options="node.affinity_type.values" 
                v-model="node.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="form control form-control-sm w-100"/>
              </div>
              <div v-if="node.affinity_type && node.affinity_type.type == 'product_category'" class="w-50 me-4">
                <multiselect
                :options="categories" 
                v-model="node.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                class="form control form-control-sm w-100"/>
              </div>
              <div class="w-25" v-if="node.affinity_type">
                <div>
                  <multiselect
                    :options="selectedField?.operatorsFields || []" 
                    v-model="aux_node.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    class="form control form-control-sm w-100"
                    @input="onAffinityOperatorSelected"/>
                </div>
              </div>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'contact_list_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex w-100 pe-0 ps-0">
                <multiselect
                  :options="selectedField?.operators || []"  
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onListOperatorSelected(aux_node)"
                />

                <multiselect
                  :options="contactLists"
                  v-model="node.term"
                  track-by="_id"
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="true"
                  :placeholder="$t('broadcasts.contact_list_placeholder')"
                  />
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'dates_rage'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="w-50 me-2">
              <div>
                <multiselect
                  :options="selectedField?.operators || []" 
                  v-model="node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  class="form control w-100 ms-0"
                  />
              </div>
            </div>
            <div class="w-25 me-2">
              <div class="d-inline-flex align-items-baseline">
                <b-form-input type="date" class="form-control w-100 me-2" min="1" v-model="node.term" ></b-form-input>
              </div>
            </div>
        </div>


        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'purchase_value'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-baseline ps-0">
            <div class="w-25 me-2">
              <div>
                <multiselect
                  :options="selectedField?.operatorsFields || []" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  class="form control w-100 ms-0"
                  @input="onOperatorSelected"
                  />
              </div>
            </div>

            $ <b-form-input type="number" class="form-control w-25 me-2" min="1" v-model="node.term" :placeholder="$t('audiences.audience_conditions_purchase_value_placeholder')"  ></b-form-input>

            <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="aux_times.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    class="form control w-100 ms-0"
                    @input="onTimesOperatorSelected"
                    />
                </div>
              </div>

              <div class="w-25 me-2">
                <div class="d-inline-flex align-items-baseline">
                  <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small mb-0"> {{node.times.value == 1 ? $t("personalizations.experience_targeting_session_time") :  $t("personalizations.experience_targeting_session_times")}}</p>
                </div>
              </div>
            </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'view_page_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex ps-0">
            <multiselect
              :options="page_Types" 
              v-model="node.term"
              selectLabel=""
              deselectLabel=""
              :placeholder="$t('audiences.audience_conditions_pages_placeholder')"
              :multiple="true"
              track-by="id"
              label="label"
              :show-labels="false"
              class="form control w-50 me-2"
            />

              <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="aux_times.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    class="form control w-100"
                    @input="onTimesOperatorSelected"
                    :allow-empty="false"
                    :show-labels="false"
                    />
                </div>
              </div>
              <div class="w-25 me-2">
                <div class="d-inline-flex align-items-baseline">
                  <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small"> {{node.times.value == 1 ? $t("personalizations.experience_targeting_session_time") :  $t("personalizations.experience_targeting_session_time")}}</p>
                </div>
              </div>
          </div>
        </div>
				<div class="col-sm-1">
						<button
						type="button"
						v-on:click="onRemoveCondition()"
						class="btn btn-sm btn-outline-danger">
						{{ $t('audiences.audience_conditions_remove_condition')  }}
				</button>
				</div>
		</div>
</template>