<script>
/**
 * Profile audience Fields
 */
export default {
  components: {
  },
  data() {
    return {

      fields:[
        {
          group: 'Datos de la compra',
          icon:'mdi mdi-cart',
          values:[
            { label: 'Monto de la compra', value: 'purchase_value'},
            { label: 'Compra de productos', value: 'interaction_purchase'},
          ],
        },
        {
          group: 'Nivel de membresía',
          icon:'mdi mdi-account-star-outline',
          value: 'membership'
        },
        {
          group: this.$t("personalizations.experience_fields_audience"),
          icon:'mdi mdi-account-group',
          value: 'audience'
        },
        {
          group: this.$t("audiences.audience_conditions_fields_customer_properties"),
          icon:"mdi mdi-database",
          value: 'customer_field'
        },
        {
          group: this.$t("personalizations.experience_fields_group_time"),
          icon:'mdi mdi-calendar',
          values:[
            { label: this.$t("personalizations.experience_fields_dates"), value: "dates"},
            { label: this.$t("personalizations.experience_fields_days"), value: "days"},
            { label: this.$t("personalizations.experience_fields_hours"), value: "hours"}
          ]
        },
        
      ]
    };
  },
  mounted() {},
  props: {
    custom_interactions:Array
  },
  emits:['onValueClicked'],
  methods: {
    onGroupClicked(group){
      if(!group.values){
        this.$emit('onValueClicked',group.value);
      }
    },
    onValueSelected(value){
      this.$emit('onValueClicked',value.value);
    }
  },
  watch: {
    custom_interactions: {
      immediate:true,
      handler(newVal){
        if(newVal && newVal.length > 0){
          const field =this.targetFields.filter(a=>a.id == 'custom_interactions')[0]
          newVal.forEach((c)=>{
            field.values.push(
              { label: c.name, value:c.key},
            )
          })
        }
      }
    }
  }
};
</script>
<template>
  <div>
    <p class="small"></p>
    <div v-for="(field, index) in fields" :key="index" class="mb-2">
      <div v-b-toggle="'accordion-' + index" class="group-header w-100 bg-light border border-1 border-start-0 border-end-0 d-flex align-items-center interact" style="border-color: #ced4da !important; height: 38px;" role="tab" v-on:click="onGroupClicked(field)">
        <button class="btn btn-link text-start text-decoration-none text-muted fw-bold me-auto"><div class="text-primary"><i :class="field.icon"></i> {{ `${field.group}` }}</div></button>
      </div>
      <b-collapse :id="`accordion-${index}`" role="tabpanel" class="mt-2" accordion="my-accordion">
        <ul>
          <li class="dropdown-item py-1 ps-0 interact" v-for="o in field.values" :key="o.label" v-on:click="onValueSelected(o)"> {{ o.label }}</li>
        </ul>
      </b-collapse>
    </div>
  </div>
</template>
