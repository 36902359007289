<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";
import ProductQueryBuilderRule from "@/components/widgets/queryBuilderProductsRule.vue"
import LiveShoppingQueryBuilderRule from "@/components/widgets/queryBuilderLiveShoppingRule.vue";
import _debounce from 'lodash.debounce';

import {
  customerMethods,
  interactionMethods
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      submitted:false,
      booleanValues: [
        {
          id: true,
          label: this.$t("common.yes"),
        },
        {
          id: false,
          label: this.$t("common.no"),
        },
      ],
      listOperators: [
        {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
        {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
      ],
      textOperators:[
        {"label" : this.$t("audiences.audience_conditions_contains"), "id" : "like"},
        {"label" : this.$t("audiences.audience_conditions_no_contains"), "id" : "not_like"},
      ],
      numberOperators:[
        {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
        {"label" : this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
        {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
      ],

      loyaltyFields:[
        { group: 'Al realizar compra',
          values: [
            {
              label: 'Compra de productos',
              value: "interaction_purchase",
              operator: '',
              term: {
                component: "products_component",
                value: 'products_condition',
              }
            },
            {
              label: 'Monto de la compra',
              value: "purchase_value",
              operator: '>' ,
              operatorsFields: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              term: {
                component: "purchase_value",
                value: 'purchase_value',
							}
            },
          ],
        },
        { group: this.$t("personalizations.experience_fields_group_customers"),
          values: [
            {
              label: this.$t("personalizations.experience_fields_audience"),
              value: "audience",
              operator: 'in',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
                {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
              ],
              term: {
                component: "audiences_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_group_contact_list"),
              value: "contact_lists",
              operator: 'in',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
                {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
              ],
              term: {
                component: "contact_list_component",
              }
            },
            {
              label: 'Nivel de membresía',
              value: "membership",
              operator: 'in',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
                {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
              ],
              term: {
                component: "memberships_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_customer_properties"),
              value: "customer_field",
              operator: '',
              term: {
                component: "customer_page_component",
              }
            },
          ]
        },
        {
              group: this.$t("personalizations.experience_fields_group_time"),
              values: [
              {
                label: this.$t("personalizations.experience_fields_dates"),
                value: "dates",
                operator: 'in',
                operators: [
                  {"label" : this.$t("personalizations.experience_fields_before") , "id" : "<"},
                  {"label" :this.$t("personalizations.experience_fields_after") , "id" : ">"},
                  {"label" : this.$t("personalizations.experience_fields_equal_date") , "id" : "="},
                ],
                term: {
                  component: "dates_rage",
                }
              },
              {
                label: this.$t("personalizations.experience_fields_days"),
                value: "days",
                operator: 'in',
                term: {
                  component: "multiselect",
                  values: [
                    {
                      id:0,
                      label: this.$t("availability.availability_days_monday")
                    },
                    {
                      id:1,
                      label: this.$t("availability.availability_days_tuesday")
                    },
                    {
                      id:2,
                      label: this.$t("availability.availability_days_wednesday")
                    },
                    {
                      id:3,
                      label: this.$t("availability.availability_days_thursday")
                    },
                    {
                      id:4,
                      label: this.$t("availability.availability_days_friday")
                    },
                    {
                      id:5,
                      label: this.$t("availability.availability_days_saturday")
                    },
                    {
                      id:6,
                      label: this.$t("availability.availability_days_sunday")
                    },
                  ]
                }
              },
              {
                label:this.$t("personalizations.experience_fields_hours"),
                value: "hours",
                operator: 'in',
                term: {
                  component: "multiselect",
                  values: [
                    {
                      id:0,
                      label: this.$t("personalizations.experience_fields_hours_morning")
                    },
                    {
                      id:1,
                      label: this.$t("personalizations.experience_fields_hours_afternoon")
                    },
                    {
                      id:2,
                      label: this.$t("personalizations.experience_fields_hours_night")
                    },
                  ]
                }
              }
              ]
            }
      ],
			selectedField: null,
      operators:[],
      
      aux_node: {
        operator: ''
      },
      aux_times: {
        operator: '>'
      },
    
      isNodeValid : false
    }
  },
  components: { Multiselect, ProductQueryBuilderRule, LiveShoppingQueryBuilderRule},
  mounted() {},
  created(){
    this.debounceValidateNode = _debounce(this.validateNode.bind(this), 1000);
  },
  methods: {
    ...customerMethods,
    ...interactionMethods,

    operatorFromCustomerField(field){
      if(field && field.type){
        switch (field.type){
          case 'list':
            this.aux_node.operator = this.listOperators.filter(o=>o.id == 'in')[0];
            return 'in';
          case 'boolean':
          this.aux_node.operator = this.booleanValues.filter(o=>o.id == '=')[0];
            return '=';
          case 'text':
          this.aux_node.operator = this.textOperators.filter(o=>o.id == 'like')[0];
            return 'like';
          case 'number':
          this.aux_node.operator = this.numberOperators.filter(o=>o.id == '>')[0];
            return '>';
          default:
            return '' 
        }
      }
      return '';
    },

    getLoyaltyFieldsByValue(value){
      
      let selectedField = null
      
      this.loyaltyFields.forEach((a)=>{
          if(a.values && a.values.length > 0){
            a.values.forEach((v)=>{
              if(v.value == value){
                selectedField = v;
              }
            })
          }
      })

      return selectedField
    },
      onLoyaltyFiedSelected(type, isUpdate=false){

        if(isUpdate){
          type = this.getLoyaltyFieldsByValue(type); 
          this.aux_node.field = type; 
        }

        if(!type)
          return;

        if(type?.value == 'country'){
          this.loadCountries();
        }
        this.operators= type?.operators || [];
        this.selectedField = type;
        this.node.field = type?.value;

        if(!isUpdate || this.node.new)
          this.node.operator = type.operator;
        
        if(this.selectedField.term.component == 'products_component' || this.selectedField.term.component == 'custom_interaction_component' || this.selectedField.term.component == 'live_shopping_component'  ){
          if(!isUpdate || this.node.new){
              this.node.term= {
              type:"and",
              children: [
                {
                  field:  'any',
                  operator: null,
                  term: null,
                }
              ]
            },
            
            delete this.node.affinity_type;
          }
        }else{
          if(!isUpdate){
            this.node.term = '',
            delete this.node.affinity_type;
          }
        }
        delete this.node.new;
        
    },
    onRemoveCondition(){
      this.$emit('removeCondition',this.index);
      if(this.is_explorer){
        this.$emit('applyExplore')
      }
    },
    onAffinityTypeSelected(node, isUpdate = false){
      if(!isUpdate){
        node.term = null
      }
    },
    onCustomerFieldSelected(node, isUpdate = false){
      if(node){
        if(!isUpdate){
          node.operator = this.operatorFromCustomerField(node.customer_field);
          node.term = null
        }
      }else{
        node.operator = null;
        node.term = null,
        this.aux_node.operator = null;
      }
    },

    onAffinityOperatorSelected(operator, isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.id;
      }else{
        this.aux_node.operator = this.selectedField.operatorsFields.filter(o=> o.id== operator).length > 0 ? this.selectedField.operatorsFields.filter(o=> o.id== operator) [0] :null ;
      }
    },

    onTimesOperatorSelected(time, isUpdate=false){
      if(!isUpdate){
        this.node.times.operator = time.id;
        this.debounceValidateNode();
      }else{
        this.aux_times.operator = this.selectedField?.operators?.filter(o=> o.id== time.operator).length > 0 ? this.selectedField?.operators?.filter(o=> o.id== time.operator) [0] : null ;
      }
    },
    onOperatorSelected(operator, isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator?.id;
      }else{
        this.aux_node.operator = this.selectedField.operatorsFields?.filter(o=> o.id== operator).length > 0 ? this.selectedField.operatorsFields?.filter(o=> o.id== operator)[0] : null ;
      }
    },
    onListOperatorSelected(operator,isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.operator?.id;
      }else{
        this.aux_node.operator = this.listOperators.filter(o=> o.id== operator).length > 0 ? this.listOperators.filter(o=> o.id== operator)[0] : null ;
      }
    },
    onTextOperatorSelected(operator,isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.operator?.id;
      }else{
        this.aux_node.operator = this.textOperators.filter(o=> o.id== operator).length > 0 ? this.textOperators.filter(o=> o.id== operator)[0] : null ;
      }
    },
    onNumberOperatorSelected(operator,isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.operator?.id;
      }else{
        this.aux_node.operator = this.numberOperators.filter(o=> o.id== operator).length > 0 ? this.numberOperators.filter(o=> o.id== operator)[0] : null ;
      }
    },
    loadCountries(){
      const loader = this.$loading.show();
      
      this.getCustomersCountries().then((countries)=>{
        this.countries = countries;
      }).catch(()=>{
        
      }).finally(()=>{
        loader.hide();
      })
    },
    validateNode(){
      if(this.selectedField.term?.component == 'view_page_component'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term?.length > 0 && this.node.times?.operator && this.node.times?.value;
      }else if(this.selectedField.term?.component == 'visit_url'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term && this.node.times?.operator && this.node.times?.value;
      }else if(this.selectedField.term?.component == 'input_num'){
        this.isNodeValid = this.node?.field && this.node.times?.operator && this.node.times?.value;
      }else if(this.selectedField.term?.component == 'purchase_value'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term ;
      }else if(this.selectedField.term?.component == 'rfm_component'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term?.length > 0;
      }else if(this.selectedField.term?.component == 'contact_list_component'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term?.length > 0;
      }else if(this.selectedField.term?.component == 'customer_page_component'){
        this.isNodeValid = this.node?.field && this.node?.customer_field && this.node?.operator && this.node?.term;
      }else if(this.selectedField.term?.component == 'affinity_page_component'){
        this.isNodeValid = this.node?.field && this.node?.affinity_type &&  this.node?.operator && this.node?.term;
      }else if(this.selectedField.term?.component == 'country'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term?.length > 0
      }else if(this.selectedField.term?.component == 'lifecycle_component' || this.selectedField.term?.component == 'likehood_component'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term
      }
      else if(this.selectedField.term?.component == 'live_shopping_component'){
        let is_ok = this.node.term?.children?.length > 0; 
        this.node.term?.children?.forEach((c)=>{
          is_ok = c.field?.id == 'any' ? is_ok : is_ok && (c.field && c.operator && c.term)
        })
        this.isNodeValid = this.node?.field && this.node.times?.operator && this.node.times?.value && is_ok;
      }else if(this.selectedField.term?.component == 'custom_interaction_component'){
        let is_ok = this.node.term?.children?.length > 0; 
        this.node.term?.children?.forEach((c)=>{
          is_ok = c.field?.column_name == 'any' ? is_ok : is_ok && (c.field && c.operator && c.term)
        })
        this.isNodeValid = this.node?.field && this.node.times?.operator && this.node.times?.value && is_ok;
      }else if(this.selectedField.term?.component == 'products_component'){
        let is_ok = this.node.term?.children?.length > 0; 
        this.node.term?.children?.forEach((c)=>{
          is_ok = c.field?.column_name == 'any' ? is_ok : is_ok && (c.field && c.operator && c.term && ((typeof(c.term) == typeof([]) && c.term.length > 0) || typeof(c.term) != typeof([]) ))
        })
        this.isNodeValid = this.node?.field && is_ok;
      }
    }
  },
  props: {
    node: Object,
    index: Number,
    properties_products: Array,
    properties_crm: Array,
    audiences: Array,
    categories: Array,
    rfm_segments: Array,
    custom_interactions: Array,
    campaigns: Array,
    memberships: Array,
    hosts: Array,
    contactLists: Array,
    is_explorer : Boolean
    
  },
	emits:["removeCondition", "applyExplore"],
  watch: {
    node:{
      immediate: true,
      handler(newVal){
        if(newVal && newVal.field){
          this.onLoyaltyFiedSelected(newVal.field,true);

          if(newVal.affinity_type){
            this.onAffinityTypeSelected(newVal,true);
          }

          if(newVal.times){
            this.onTimesOperatorSelected(newVal.times,true);
          }

          if(newVal.customer_field){
            this.onCustomerFieldSelected(newVal,true);
          }

          if(newVal.operator == 'in' || newVal.operator == 'not_in' ){
            this.onListOperatorSelected(newVal.operator,true);
          }
          else if(newVal.operator == 'like' || newVal.operator == 'not_like' ){
            this.onTextOperatorSelected(newVal.operator,true);
          }
          else if (newVal.operator == '>' || newVal.operator == '<' || newVal.operator == '='){
            this.onNumberOperatorSelected(newVal.operator,true);
          }
          else if(newVal.operator){
            this.onOperatorSelected(newVal.operator,true);
          }

          this.validateNode()
        }
      }
    },
   custom_interactions:{
      immediate:true,
      handler(newVal){
        if(newVal && newVal.length > 0){
          const field =this.loyaltyFields.filter(a=>a.id == 'custom_interactions')
          newVal.forEach((c)=>{

          field[0].values.push({
              label: c.name,
              value: c.key,
              parameters: c.parameters.filter(p=> p.type != 'item'),
              term: {
                component: "custom_interaction_component",
              }
            })
          });

          if(this.node && this.node.field){
            this.onLoyaltyFiedSelected(this.node.field,true);
          }
        }
      }
    },
    'node.operator': {
      handler: function(newOperator) {
        // eslint-disable-next-line no-console
        console.log('Operator changed:', newOperator);
        this.debounceValidateNode();
      },
      deep: true
    },
    'node.term': {
      handler: function(newOperator) {
        // eslint-disable-next-line no-console
        console.log('term changed:', newOperator);
        this.debounceValidateNode();
      },
      deep: true
    },
    'node.times': {
      handler: function(newTimes) {
        // eslint-disable-next-line no-console
        console.log('times changed:', newTimes);
        this.debounceValidateNode();
      },
      deep: true
    }
  },
}

 </script>

<template>
    
	<div class="row">
				<div class="col-sm-3 me-0">
						<multiselect
						:options="loyaltyFields"
						group-values="values"
						group-label="group"
						track-by="value"
						label="label"
						v-model="aux_node.field"
						selectLabel=""
						deselectLabel=""
            :placeholder="$t('audiences.audience_conditions_field_placeholder')"
            :allow-empty="false"
            :show-labels="false"
						@select="onLoyaltyFiedSelected"
						/>
				</div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'customer_page_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-center ps-0 pe-0">
            <multiselect
              :options="properties_crm" 
              v-model="node.customer_field"
              track-by="column_name"
              label="name"
              selectLabel=""
              deselectLabel=""
              class="form control w-25"
              @input="onCustomerFieldSelected(node)"
              :show-labels="false"
              :allow-empty="false"
            />
            <div v-if="node.customer_field && node.customer_field.type == 'list'" class="w-75 ms-2 me-2">
              <div class="d-inline-flex w-100">
                <multiselect
                  :options="listOperators" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onListOperatorSelected(aux_node)"
                />

                <multiselect
                  :options="node.customer_field.values" 
                  v-model="node.term"
                  track-by="id"
                  label="value"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="true"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="form control"/>
              </div>
            </div>
            <div v-if="node.customer_field && node.customer_field.type == 'text'" class="w-75 ms-2 me-2">
              <div class="d-inline-flex w-100">
                <multiselect
                  :options="textOperators" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onTextOperatorSelected(aux_node)"
                />
                <b-form-input type="text" class="form-control" v-model="node.term" placeholder="Regex"></b-form-input>
              </div>
            </div>
            <div v-if="node.customer_field && node.customer_field.type == 'boolean'" class="w-50 me-2">
              <multiselect
              :options="booleanValues" 
              v-model="node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control form-control-sm w-100"/>
            </div>
            <div v-if="node.customer_field && node.customer_field.type == 'number'" class="w-75 ms-2 me-2">
              <div class="d-inline-flex align-items-baseline">
                <div class="me-2">
                  <div>
                    <multiselect
                      :options="numberOperators" 
                      v-model="aux_node.operator"
                      track-by="id"
                      label="label"
                      selectLabel=""
                      deselectLabel=""
                      :multiple="false"
                      class="form control ms-0"
                      @input="onNumberOperatorSelected(aux_node)"
                      style="width: 200px;"
                      />
                  </div>
                </div>
                <b-form-input type="number" class="form-control" min="1" v-model="node.term" ></b-form-input>
              </div>
            </div>
            <i class="fas fa-exclamation-triangle text-warning ms-auto text-end" v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'contact_list_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex w-100 pe-0 ps-0 align-items-center">
                <multiselect
                  :options="listOperators" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onListOperatorSelected(aux_node)"
                />

                <multiselect
                  :options="contactLists"
                  v-model="node.term"
                  track-by="_id"
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="true"
                  :placeholder="$t('broadcasts.contact_list_placeholder')"
                  class="me-2"
                  />
                  <i class="fas fa-exclamation-triangle text-warning ms-auto text-end " v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'purchase_value'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-baseline ps-0">
            <div class="w-25 me-2">
              <div>
                <multiselect
                  :options="selectedField?.operatorsFields || []" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :show-labels="false"
                  :allow-empty="false"
                  class="form control w-100 ms-0"
                  @input="onOperatorSelected"
                  />
              </div>
            </div>

            $ <b-form-input type="number" class="form-control w-25 me-2" min="1" v-model="node.term" :placeholder="$t('audiences.audience_conditions_purchase_value_placeholder')"  ></b-form-input>
            
            <i class="fas fa-exclamation-triangle text-warning ms-auto text-end" v-if="!isNodeValid"></i>
          </div>
        </div>
				<div v-if="node.field && selectedField?.term?.component == 'input_num'" class="col-sm-8 d-inline-flex row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-baseline ps-0 ms-auto">
            <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="aux_times.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="form control w-100 ms-0"
                    @input="onTimesOperatorSelected"
                    />
                </div>
              </div>
            <div class="d-inline-flex align-items-baseline">
              <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small mb-0"> {{node.times.value == 1 ? $t("audiences.audience_conditions_at_least_time") :  $t("audiences.audience_conditions_at_least_times")}}</p>
            </div>
            <i class="fas fa-exclamation-triangle text-warning text-end text-end ms-auto" v-if="!isNodeValid"></i>
          </div>
				</div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'multiselect'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <multiselect
              :options="selectedField.term.values || []" 
              v-model="node.term"
              selectLabel=""
              track-by="id"
              label="label"
              deselectLabel=""
              :multiple="true"
              class="form control form-control-sm w-50"
            />
        </div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'products_component'" class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
					<i class="fas fa-exclamation-triangle text-warning float-end mt-2" v-if="!isNodeValid"></i>
          <ProductQueryBuilderRule :query="node.term" :properties_products="properties_products" :hideTimes="true" :categories="categories" :times="null" /> 
				</div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'live_shopping_component'" class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
          <i class="fas fa-exclamation-triangle text-warning float-end mt-2" v-if="!isNodeValid"></i>
					<LiveShoppingQueryBuilderRule :query="node.term" :times="node.times" :campaigns="campaigns" :isInvitation="selectedField?.value == 'live_shop_invitations_count'" :hosts="hosts" /> 
				</div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'dates_rage'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="w-50 me-2">
              <div>
                <multiselect
                  :options="selectedField?.operators || []" 
                  v-model="node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  class="form control w-100 ms-0"
                  />
              </div>
            </div>
            <div class="w-25 me-2">
              <div class="d-inline-flex align-items-baseline">
                <b-form-input type="date" class="form-control w-100 me-2" min="1" v-model="node.term" ></b-form-input>
              </div>
            </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'audiences_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex">
            <div class="w-25">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="node.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allowEmpty="false"
                    class="form control form-control-sm w-100"/>
                </div>
              </div>
              <multiselect
                :options="audiences" 
                v-model="node.term"
                track-by="_id"
                label="name"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                class="form control form-control-sm w-100 ms-2"
              />
          </div>
				</div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'memberships_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex">
            <div class="w-25">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="node.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allowEmpty="false"
                    class="form control form-control-sm w-100"/>
                </div>
              </div>
              <multiselect
                :options="memberships" 
                v-model="node.term"
                track-by="_id"
                label="name"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                class="form control form-control-sm w-100 ms-2"
              />
          </div>
				</div>
				<div class="col-sm-1">
						<button
						type="button"
						v-on:click="onRemoveCondition()"
						class="btn btn-sm btn-outline-danger">
						{{ $t('audiences.audience_conditions_remove_condition')  }}
				</button>
				</div>
		</div>
</template>