<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";
import ProductQueryBuilderRule from "./queryBuilderProductsRule.vue"
import InteractionQueryBuilderRule from "./queryBuilderInteractionsRule.vue";
import LiveShoppingQueryBuilderRule from "./queryBuilderLiveShoppingRule.vue";
import _debounce from 'lodash.debounce';

import {
  customerMethods,
  interactionMethods
} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
      submitted:false,
      booleanValues: [
        {
          id: true,
          label: this.$t("common.yes"),
        },
        {
          id: false,
          label: this.$t("common.no"),
        },
      ],
      listOperators: [
        {"label" : this.$t("audiences.audience_conditions_belongs") , "id" : "in"},
        {"label" : this.$t("audiences.audience_conditions_not_belongs"), "id" : "not_in"},
      ],
      textOperators:[
        {"label" : this.$t("audiences.audience_conditions_contains"), "id" : "like"},
        {"label" : this.$t("audiences.audience_conditions_no_contains"), "id" : "not_like"},
      ],
      numberOperators:[
        {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
        {"label" : this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
        {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
      ],
      audienceFields:[
        {
          group: this.$t("audiences.audience_conditions_group_behaviours"),
          values: [
            {
              label: this.$t("audiences.audience_conditions_fields_pages_visited"),
              value: "pages_viewed",
              operator: 'in',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "view_page_component",
                value: 'pages_viewed_count',
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_url_visit"),
              value: "visit_url",
              operator: 'like',
              operatorsFields: [
                {"label" : this.$t("audiences.audience_conditions_contains"), "id" : "like"},
                {"label" : this.$t("audiences.audience_conditions_no_contains"), "id" : "not_like"},
              ],
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "visit_url",
                value: 'visit_url',
							}
            },
						{
              label: this.$t("audiences.audience_conditions_fields_added_to_cart"),
              value: "added_to_cart",
              operator: '',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num",
                value: 'added_to_cart_count',
							}
            },
						{
              label: this.$t("audiences.audience_conditions_fields_added_to_like"),
              value: "added_to_like",
              operator: '' ,
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num",
              }
            },
            /*{
              label: this.$t("audiences.audience_conditions_fields_rate_products"),
              value: "rate_products",
              operator: '',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num",
              }
            },*/
            {
              label: this.$t("audiences.audience_conditions_fields_purchases"),
              value: "purchases",
              operator: '',
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "input_num",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_purchase_value"),
              value: "purchase_value",
              operator: '>' ,
              operatorsFields: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              operators: [
                {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
                {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
                {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
              ],
              times:{
                "operator" : ">",
                "value": 1
              },
              term: {
                component: "purchase_value",
                value: 'purchase_value',
							}
            },
            {
              label: "Cluster RFM",
              value: "cluster_rfm",
              operator: 'in',
              term: {
                component: "rfm_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_group_contact_list"),
              value: "contact_lists",
              operator: 'in',
              term: {
                component: "contact_list_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_customer_properties"),
              value: "customer_field",
              operator: '',
              term: {
                component: "customer_page_component",
              }
            },
          ]
        },
        {
          group: this.$t("audiences.audience_conditions_group_predictive"),
          values: [
            {
              label: this.$t("audiences.audience_conditions_fields_affinity"),
              value: "affinity",
              operator: '',
              operatorsFields: [
                {"label" : this.$t("audiences.audience_conditions_affinity_highest"), "id" : "highest"},
                {"label" : "Top 3", "id" : "top_3"},
                {"label" : "Top 5", "id" : "top_5"},
                {"label" : "Top 10", "id" : "top_10"},
              ],
              term: {
                component: "affinity_page_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_likehood_purchase"),
              value: "likehood_purchase",
              operator: '=',
              term: {
                component: "likehood_component",
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_customer_cycle_status"),
              value: "customer_cycle_status",
              operator: '=',
              term: {
                component: "lifecycle_component",
              }
            },
          ]
        },
        { group: this.$t("audiences.audience_conditions_group_interactions"),
          id: 'interactions',
          values: [
            {
              label: this.$t("audiences.audience_conditions_fields_interaction_view"),
              value: "interaction_view",
              operator: '',
              term: {
                component: "products_component",
                value: 'products_condition',
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_interaction_cart"),
              value: "interaction_cart",
              operator: '',
              term: {
                component: "products_component",
                value: 'products_condition',
              }
            },
            /*{
              label: this.$t("audiences.audience_conditions_fields_interaction_rate"),
              value: "interaction_rate",
              operator: '',
              term: {
                component: "products_component",
                value: 'products_condition',
              }
            },*/
            {
              label: this.$t("audiences.audience_conditions_fields_interaction_like"),
              value: "interaction_like",
              operator: '',
              term: {
                component: "products_component",
                value: 'products_condition',
              }
            },
            {
              label: this.$t("audiences.audience_conditions_fields_interaction_purchase"),
              value: "interaction_purchase",
              operator: '',
              term: {
                component: "products_component",
                value: 'products_condition',
              }
            }],
        },
        {
          group: 'Custom Interactions',
          id: 'custom_interactions',
          values: []
        },
        {
          group: this.$t("audiences.audience_conditions_group_demographic"),
          values: [
            {
              label: this.$t("audiences.audience_conditions_fields_country"),
              value: "country",
              operator: 'in',
              term: {
                component: "country",
              }
            }
          ]
        },
        {
          group: "Live Shopping",
          values:[
            { 
              label: this.$t("audiences.audience_conditions_fields_live_Shopping_invitations"),
              value: "live_shop_invitations_count",
              operator: '',
              term: {
                component: "live_shopping_component",
              }
            },
            { 
              label: this.$t("audiences.audience_conditions_fields_live_Shopping_events"), 
              value: "live_shop_events_count",
              operator: '',
              term: {
                component: "live_shopping_component",
              }
            }
          ]
        },
      ],
			selectedField: null,
      operators:[],
      page_Types: [
        {id: "product_detail", label: this.$t("personalizations.page_type_product_detail")},
        {id: "cart", label: this.$t("personalizations.page_type_cart")},
        {id: "checkout", label: 'Checkout'},
        {id: "category", label: this.$t("personalizations.page_type_category") },
        {id: "home", label: this.$t("personalizations.page_type_home") },
        {id: "product_listing", label: this.$t("personalizations.page_type_product_listing") },
        {id: "any", label: this.$t("personalizations.page_type_any") }
      ],
      
      rfm_clusters:[
      {
          name: "Recent customers with low spend",
          id:111
      },
      {
          name: "Recent customers with medium spend",
          id:112
      },
      {
          name: "Promising customers with medium spend",
          id:122
      },
      {
          name: 'Customers needing attention with very high spend',
          id:214
      },
      {
          name: "At risk customers with high spend",
          id:223
      },
    ],
    aux_node: {
      operator: ''
    },
    aux_times: {
      operator: '>'
    },
    countries:[],
    likehoods:[
      {id:'high', label: this.$t("audiences.audience_conditions_high")},
      {id:'normal', label: this.$t("audiences.audience_conditions_normal")},
      {id:'low', label: this.$t("audiences.audience_conditions_low")}
    ],
    lifecycle:[
      {id:'active', label: this.$t("audiences.audience_conditions_active")},
      {id:'inactive', label: this.$t("audiences.audience_conditions_inactive")},
      {id:'churn', label: this.$t("audiences.audience_conditions_churn")}
    ],
    productsConditions: [
        {"label" : this.$t('journeys.journey_workflow_components_wait_interaction_any'), "id" : "any"},
        {"label" : this.$t('journeys.journey_workflow_components_wait_interaction_with_properties'), "id" : "filter"},
      ],
      isNodeValid : false
    }
    
    
  },
  components: { Multiselect, ProductQueryBuilderRule, InteractionQueryBuilderRule, LiveShoppingQueryBuilderRule},
  mounted() {},
  created(){
    this.debounceValidateNode = _debounce(this.validateNode.bind(this), 1000);
  },
  methods: {
    ...customerMethods,
    ...interactionMethods,

    operatorFromCustomerField(field){
      if(field && field.type){
        switch (field.type){
          case 'list':
            this.aux_node.operator = this.listOperators.filter(o=>o.id == 'in')[0];
            return 'in';
          case 'boolean':
          this.aux_node.operator = this.booleanValues.filter(o=>o.id == '=')[0];
            return '=';
          case 'text':
          this.aux_node.operator = this.textOperators.filter(o=>o.id == 'like')[0];
            return 'like';
          case 'number':
          this.aux_node.operator = this.numberOperators.filter(o=>o.id == '>')[0];
            return '>';
          default:
            return '' 
        }
      }
      return '';
    },

    getAudienceFieldByValue(value){
      
      let selectedField = null
      
      this.audienceFields.forEach((a)=>{
          if(a.values && a.values.length > 0){
            a.values.forEach((v)=>{
              if(v.value == value){
                selectedField = v;
              }
            })
          }
      })

      return selectedField
    },
      onAudienceFiedSelected(type, isUpdate=false){

        if(isUpdate){
          type = this.getAudienceFieldByValue(type); 
          this.aux_node.field = type; 
        }

        if(!type)
          return;

        if(type?.value == 'country'){
          this.loadCountries();
        }
        this.operators= type?.operators || [];
        this.selectedField = type;
        this.node.field = type?.value;

        if(!isUpdate || this.node.new)
          this.node.operator = type.operator;
        
        if(this.selectedField.term.component == 'products_component' || this.selectedField.term.component == 'custom_interaction_component' || this.selectedField.term.component == 'live_shopping_component'  ){
          if(!isUpdate || this.node.new){
              this.node.term= {
              type:"and",
              children: [
                {
                  field:  'any',
                  operator: null,
                  term: null,
                }
              ]
            },
            this.node.times = {
              operator: '>',
              value: 1
            }
            delete this.node.affinity_type;
          }
        }else{
          if(!isUpdate){
            this.node.term = '',
            this.node.times = type.times,
            delete this.node.affinity_type;
          }
        }
        delete this.node.new;
        
    },
    onRemoveCondition(){
      this.$emit('removeCondition',this.index);
      if(this.is_explorer){
        this.$emit('applyExplore')
      }
    },
    onAffinityTypeSelected(node, isUpdate = false){
      if(!isUpdate){
        node.term = null
      }
    },
    onCustomerFieldSelected(node, isUpdate = false){
      if(node){
        if(!isUpdate){
          node.operator = this.operatorFromCustomerField(node.customer_field);
          node.term = null
        }
      }else{
        node.operator = null;
        node.term = null,
        this.aux_node.operator = null;
      }
    },

    onAffinityOperatorSelected(operator, isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.id;
      }else{
        this.aux_node.operator = this.selectedField.operatorsFields.filter(o=> o.id== operator).length > 0 ? this.selectedField.operatorsFields.filter(o=> o.id== operator) [0] :null ;
      }
    },

    onTimesOperatorSelected(time, isUpdate=false){
      if(!isUpdate){
        this.node.times.operator = time.id;
        this.debounceValidateNode();
      }else{
        this.aux_times.operator = this.selectedField?.operators?.filter(o=> o.id== time.operator).length > 0 ? this.selectedField?.operators?.filter(o=> o.id== time.operator) [0] : null ;
      }
    },
    onOperatorSelected(operator, isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.id;
      }else{
        this.aux_node.operator = this.selectedField.operatorsFields?.filter(o=> o.id== operator).length > 0 ? this.selectedField.operatorsFields?.filter(o=> o.id== operator)[0] : null ;
      }
    },
    onListOperatorSelected(operator,isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.operator?.id;
      }else{
        this.aux_node.operator = this.listOperators.filter(o=> o.id== operator).length > 0 ? this.listOperators.filter(o=> o.id== operator)[0] : null ;
      }
    },
    onTextOperatorSelected(operator,isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.operator?.id;
      }else{
        this.aux_node.operator = this.textOperators.filter(o=> o.id== operator).length > 0 ? this.textOperators.filter(o=> o.id== operator)[0] : null ;
      }
    },
    onNumberOperatorSelected(operator,isUpdate=false){
      if(!isUpdate){
        this.node.operator = operator.operator?.id;
      }else{
        this.aux_node.operator = this.numberOperators.filter(o=> o.id== operator).length > 0 ? this.numberOperators.filter(o=> o.id== operator)[0] : null ;
      }
    },
    loadCountries(){
      const loader = this.$loading.show();
      
      this.getCustomersCountries().then((countries)=>{
        this.countries = countries;
      }).catch(()=>{
        
      }).finally(()=>{
        loader.hide();
      })
    },
    validateNode(){
      if(this.selectedField.term?.component == 'view_page_component'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term?.length > 0 && this.node.times?.operator && this.node.times?.value;
      }else if(this.selectedField.term?.component == 'visit_url'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term && this.node.times?.operator && this.node.times?.value;
      }else if(this.selectedField.term?.component == 'input_num'){
        this.isNodeValid = this.node?.field && this.node.times?.operator && this.node.times?.value;
      }else if(this.selectedField.term?.component == 'purchase_value'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term  && this.node.times?.operator && this.node.times?.value;
      }else if(this.selectedField.term?.component == 'rfm_component'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term?.length > 0;
      }else if(this.selectedField.term?.component == 'contact_list_component'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term?.length > 0;
      }else if(this.selectedField.term?.component == 'customer_page_component'){
        this.isNodeValid = this.node?.field && this.node?.customer_field && this.node?.operator && this.node?.term;
      }else if(this.selectedField.term?.component == 'affinity_page_component'){
        this.isNodeValid = this.node?.field && this.node?.affinity_type &&  this.node?.operator && this.node?.term;
      }else if(this.selectedField.term?.component == 'country'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term?.length > 0
      }else if(this.selectedField.term?.component == 'lifecycle_component' || this.selectedField.term?.component == 'likehood_component'){
        this.isNodeValid = this.node?.field && this.node?.operator && this.node?.term
      }
      else if(this.selectedField.term?.component == 'live_shopping_component'){
        let is_ok = this.node.term?.children?.length > 0; 
        this.node.term?.children?.forEach((c)=>{
          is_ok = c.field?.id == 'any' ? is_ok : is_ok && (c.field && c.operator && c.term)
        })
        this.isNodeValid = this.node?.field && this.node.times?.operator && this.node.times?.value && is_ok;
      }else if(this.selectedField.term?.component == 'custom_interaction_component'){
        let is_ok = this.node.term?.children?.length > 0; 
        this.node.term?.children?.forEach((c)=>{
          is_ok = c.field?.column_name == 'any' ? is_ok : is_ok && (c.field && c.operator && c.term)
        })
        this.isNodeValid = this.node?.field && this.node.times?.operator && this.node.times?.value && is_ok;
      }else if(this.selectedField.term?.component == 'products_component'){
        let is_ok = this.node.term?.children?.length > 0; 
        this.node.term?.children?.forEach((c)=>{
          is_ok = c.field?.column_name == 'any' ? is_ok : is_ok && (c.field && c.operator && c.term && ((typeof(c.term) == typeof([]) && c.term.length > 0) || typeof(c.term) != typeof([]) ))
        })
        this.isNodeValid = this.node?.field && this.node.times?.operator && this.node.times?.value && is_ok;
      }

      if(this.is_explorer && this.isNodeValid){
          this.$emit('applyExplore');
        }
      
    }
  },
  props: {
    node: Object,
    index: Number,
    properties_products: Array,
    properties_crm: Array,
    affinity_Types: Array,
    categories: Array,
    rfm_segments: Array,
    custom_interactions: Array,
    campaigns: Array,
    hosts: Array,
    contactLists: Array,
    is_explorer : Boolean
    
  },
	emits:["removeCondition", "applyExplore"],
  watch: {
    node:{
      immediate: true,
      handler(newVal){
        if(newVal && newVal.field){
          this.onAudienceFiedSelected(newVal.field,true);

          if(newVal.affinity_type){
            this.onAffinityTypeSelected(newVal,true);
          }

          if(newVal.times){
            this.onTimesOperatorSelected(newVal.times,true);
          }

          if(newVal.customer_field){
            this.onCustomerFieldSelected(newVal,true);
          }

          if(newVal.operator == 'in' || newVal.operator == 'not_in' ){
            this.onListOperatorSelected(newVal.operator,true);
          }
          else if(newVal.operator == 'like' || newVal.operator == 'not_like' ){
            this.onTextOperatorSelected(newVal.operator,true);
          }
          else if (newVal.operator == '>' || newVal.operator == '<' || newVal.operator == '='){
            this.onNumberOperatorSelected(newVal.operator,true);
          }
          else if(newVal.operator){
            this.onOperatorSelected(newVal.operator,true);
          }

          this.validateNode()
        }
      }
    },
   custom_interactions:{
      immediate:true,
      handler(newVal){
        if(newVal && newVal.length > 0){
          const field =this.audienceFields.filter(a=>a.id == 'custom_interactions')
          newVal.forEach((c)=>{

          field[0].values.push({
              label: c.name,
              value: c.key,
              parameters: c.parameters.filter(p=> p.type != 'item'),
              term: {
                component: "custom_interaction_component",
              }
            })
          });

          if(this.node && this.node.field){
            this.onAudienceFiedSelected(this.node.field,true);
          }
        }
      }
    },
    'node.operator': {
      handler: function(newOperator) {
        // eslint-disable-next-line no-console
        console.log('Operator changed:', newOperator);
        this.debounceValidateNode();
      },
      deep: true
    },
    'node.term': {
      handler: function(newOperator) {
        // eslint-disable-next-line no-console
        console.log('term changed:', newOperator);
        this.debounceValidateNode();
      },
      deep: true
    },
    'node.times': {
      handler: function(newTimes) {
        // eslint-disable-next-line no-console
        console.log('times changed:', newTimes);
        this.debounceValidateNode();
      },
      deep: true
    }
  },
}

 </script>

<template>
    
	<div class="row">
				<div class="col-sm-3 me-0">
						<multiselect
						:options="audienceFields"
						group-values="values"
						group-label="group"
						track-by="value"
						label="label"
						v-model="aux_node.field"
						selectLabel=""
						deselectLabel=""
            :placeholder="$t('audiences.audience_conditions_field_placeholder')"
            :allow-empty="false"
            :show-labels="false"
						@select="onAudienceFiedSelected"
						/>
				</div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'view_page_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex ps-0 align-items-center">
            <multiselect
              :options="page_Types" 
              v-model="node.term"
              selectLabel=""
              deselectLabel=""
              :placeholder="$t('audiences.audience_conditions_pages_placeholder')"
              :multiple="true"
              track-by="id"
              label="label"
              :show-labels="false"
              class="form control w-50 me-2"
            />

              <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="aux_times.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="form control w-100"
                    @input="onTimesOperatorSelected"
                    />
                </div>
              </div>

              <div class="w-25 me-2">
                <div class="d-inline-flex align-items-baseline">
                  <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small"> {{node.times.value == 1 ? $t("audiences.audience_conditions_at_least_time") :  $t("audiences.audience_conditions_at_least_times")}}</p>
                </div>
              </div>
              <i class="fas fa-exclamation-triangle text-warning" v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'affinity_page_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-center ps-0 pe-0">
            <div class="w-25">
              <multiselect
                :options="affinity_Types" 
                v-model="node.affinity_type"
                track-by="column_name"
                label="name"
                selectLabel=""
                deselectLabel=""
                class="form control"
                :show-labels="false"
                :allow-empty="false"
                @input="onAffinityTypeSelected(node)"
                />
            </div>
            <div v-if="node.affinity_type && node.affinity_type.type != 'product_category'" class="w-50 me-2">
                <multiselect
                :options="node.affinity_type.values" 
                v-model="node.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                :show-labels="false"
                class="form control form-control-sm w-100"/>
              </div>
              <div v-if="node.affinity_type && node.affinity_type.type == 'product_category'" class="w-50 me-2">
                <multiselect
                :options="categories" 
                v-model="node.term"
                track-by="id"
                label="value"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                :show-labels="false"
                :allow-empty="false"
                class="form control form-control-sm w-100"/>
              </div>
              <div class="w-25 me-3" v-if="node.affinity_type">
                <div>
                  <multiselect
                    :options="selectedField?.operatorsFields || []" 
                    v-model="aux_node.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :show-labels="false"
                    :allow-empty="false"
                    class="form control form-control-sm w-100"
                    @input="onAffinityOperatorSelected"/>
                </div>
              </div>
              <i class="fas fa-exclamation-triangle text-warning ms-auto text-end" v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'customer_page_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-center ps-0 pe-0">
            <multiselect
              :options="properties_crm" 
              v-model="node.customer_field"
              track-by="column_name"
              label="name"
              selectLabel=""
              deselectLabel=""
              class="form control w-25"
              @input="onCustomerFieldSelected(node)"
              :show-labels="false"
              :allow-empty="false"
            />
            <div v-if="node.customer_field && node.customer_field.type == 'list'" class="w-75 ms-2 me-2">
              <div class="d-inline-flex w-100">
                <multiselect
                  :options="listOperators" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onListOperatorSelected(aux_node)"
                />

                <multiselect
                  :options="node.customer_field.values" 
                  v-model="node.term"
                  track-by="id"
                  label="value"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="true"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="form control"/>
              </div>
            </div>
            <div v-if="node.customer_field && node.customer_field.type == 'text'" class="w-75 ms-2 me-2">
              <div class="d-inline-flex w-100">
                <multiselect
                  :options="textOperators" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onTextOperatorSelected(aux_node)"
                />
                <b-form-input type="text" class="form-control" v-model="node.term" placeholder="Regex"></b-form-input>
              </div>
            </div>
            <div v-if="node.customer_field && node.customer_field.type == 'boolean'" class="w-50 me-2">
              <multiselect
              :options="booleanValues" 
              v-model="node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              class="form control form-control-sm w-100"/>
            </div>
            <div v-if="node.customer_field && node.customer_field.type == 'number'" class="w-75 ms-2 me-2">
              <div class="d-inline-flex align-items-baseline">
                <div class="me-2">
                  <div>
                    <multiselect
                      :options="numberOperators" 
                      v-model="aux_node.operator"
                      track-by="id"
                      label="label"
                      selectLabel=""
                      deselectLabel=""
                      :multiple="false"
                      class="form control ms-0"
                      @input="onNumberOperatorSelected(aux_node)"
                      style="width: 200px;"
                      />
                  </div>
                </div>
                <b-form-input type="number" class="form-control" min="1" v-model="node.term" ></b-form-input>
              </div>
            </div>
            <i class="fas fa-exclamation-triangle text-warning ms-auto text-end" v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'rfm_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex w-100 pe-0 ps-0 align-items-center">
                <multiselect
                  :options="listOperators" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onListOperatorSelected(aux_node)"
                />

                <multiselect
                  :options="rfm_segments"
                  v-model="node.term"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="true"
                  :placeholder="$t('audiences.audience_conditions_rfm_clusters_placeholder')"
                  class="me-2"
                  />
                  <i class="fas fa-exclamation-triangle text-warning ms-auto text-end " v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'contact_list_component'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex w-100 pe-0 ps-0 align-items-center">
                <multiselect
                  :options="listOperators" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onListOperatorSelected(aux_node)"
                />

                <multiselect
                  :options="contactLists"
                  v-model="node.term"
                  track-by="_id"
                  label="name"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="true"
                  :placeholder="$t('broadcasts.contact_list_placeholder')"
                  class="me-2"
                  />
                  <i class="fas fa-exclamation-triangle text-warning ms-auto text-end " v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'visit_url'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-baseline ps-0">
            <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="selectedField?.operatorsFields || []" 
                    v-model="aux_node.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="form control w-100 ms-0"
                    @input="onOperatorSelected"
                    />
                </div>
              </div>
            
            <b-form-input type="text" class="form-control w-50 me-2" v-model="node.term" :placeholder="$t('audiences.audience_conditions_visit_url_placeholder')"  ></b-form-input>
            
            <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="aux_times.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="form control w-100 ms-0"
                    @input="onTimesOperatorSelected"
                    />
                </div>
              </div>

              <div class="w-25 me-2">
                <div class="d-inline-flex align-items-baseline">
                  <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small mb-0"> {{node.times.value == 1 ? $t("audiences.audience_conditions_at_least_time") :  $t("audiences.audience_conditions_at_least_times")}}</p>
                </div>
              </div>
              <i class="fas fa-exclamation-triangle text-warning" v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField.term?.component == 'purchase_value'" class="col-sm-8 row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-baseline ps-0">
            <div class="w-25 me-2">
              <div>
                <multiselect
                  :options="selectedField?.operatorsFields || []" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :show-labels="false"
                  :allow-empty="false"
                  class="form control w-100 ms-0"
                  @input="onOperatorSelected"
                  />
              </div>
            </div>

            $ <b-form-input type="number" class="form-control w-25 me-2" min="1" v-model="node.term" :placeholder="$t('audiences.audience_conditions_purchase_value_placeholder')"  ></b-form-input>

            <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="aux_times.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="form control w-100 ms-0"
                    @input="onTimesOperatorSelected"
                    />
                </div>
              </div>

              <div class="w-25 me-2">
                <div class="d-inline-flex align-items-baseline">
                  <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small mb-0"> {{node.times.value == 1 ? $t("audiences.audience_conditions_at_least_time") :  $t("audiences.audience_conditions_at_least_times")}}</p>
                </div>
              </div>
              <i class="fas fa-exclamation-triangle text-warning ms-auto text-end" v-if="!isNodeValid"></i>
            </div>
        </div>
				<div v-if="node.field && selectedField?.term?.component == 'input_num'" class="col-sm-8 d-inline-flex row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex align-items-baseline ps-0 ms-auto">
            <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="selectedField?.operators || []" 
                    v-model="aux_times.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    :allow-empty="false"
                    :show-labels="false"
                    class="form control w-100 ms-0"
                    @input="onTimesOperatorSelected"
                    />
                </div>
              </div>
            <div class="d-inline-flex align-items-baseline">
              <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="node.times.value" ></b-form-input> <p class="small mb-0"> {{node.times.value == 1 ? $t("audiences.audience_conditions_at_least_time") :  $t("audiences.audience_conditions_at_least_times")}}</p>
            </div>
            <i class="fas fa-exclamation-triangle text-warning text-end text-end ms-auto" v-if="!isNodeValid"></i>
          </div>
				</div>
        <div v-if="node.field && selectedField?.term?.component == 'country'" class="col-sm-8 d-inline-flex row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="d-inline-flex w-100 pe-0 ps-0 align-items-center">
                <multiselect
                  :options="listOperators" 
                  v-model="aux_node.operator"
                  track-by="id"
                  label="label"
                  selectLabel=""
                  deselectLabel=""
                  :multiple="false"
                  :allowEmpty="false"
                  :show-labels="false"
                  class="me-2"
                  style="width: 200px;"
                  @input="onListOperatorSelected(aux_node)"
                />

              <multiselect
                :options="countries"
                v-model="node.term"
                track-by="id"
                label="name"
                selectLabel=""
                deselectLabel=""
                :multiple="true"
                :placeholder="$t('audiences.audience_conditions_field_country_placeholder')"
                class="me-2"
                />

                <i class="fas fa-exclamation-triangle text-warning ms-auto text-end" v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term?.component == 'custom_interaction_component'" class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
          <i class="fas fa-exclamation-triangle text-warning float-end mt-2" v-if="!isNodeValid"></i>
          <InteractionQueryBuilderRule :query="node.term" :interaction_parameters="selectedField.parameters || []" :times="node.times" :categories="categories"></InteractionQueryBuilderRule>
        </div> 
        <div v-if="node.field && selectedField?.term?.component == 'likehood_component'" class="col-sm-8 d-inline-flex align-items-center row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="w-25 ps-0">
            <multiselect
              :options="likehoods" 
              v-model="node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              :show-labels="false"
              :allow-empty="false"
              class="form control"
            />
          </div>
          <div class="w-75 ms-auto text-end">
            <i class="fas fa-exclamation-triangle text-warning text-end text-end ms-auto" v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term?.component == 'lifecycle_component'" class="col-sm-8 d-inline-flex align-items-center row pt-2 pb-2 ms-0 me-0" style="background-color: rgba(85,110,230,.1);">
          <div class="w-50 ps-0">
            <multiselect
              :options="lifecycle" 
              v-model="node.term"
              track-by="id"
              label="label"
              selectLabel=""
              deselectLabel=""
              :multiple="false"
              :show-labels="false"
              :allow-empty="false"
              class="form control"
            />
          </div>
          <div class="w-50 ms-auto text-end">
            <i class="fas fa-exclamation-triangle text-warning text-end text-end ms-auto" v-if="!isNodeValid"></i>
          </div>
        </div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'products_component'" class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
					<i class="fas fa-exclamation-triangle text-warning float-end mt-2" v-if="!isNodeValid"></i>
          <ProductQueryBuilderRule :query="node.term" :properties_products="properties_products" :times="node.times" :categories="categories" /> 
				</div>
        <div v-if="node.field && selectedField?.term && selectedField?.term?.component == 'live_shopping_component'" class="col-sm-8" style="background-color: rgba(85,110,230,.1);">
          <i class="fas fa-exclamation-triangle text-warning float-end mt-2" v-if="!isNodeValid"></i>
					<LiveShoppingQueryBuilderRule :query="node.term" :times="node.times" :campaigns="campaigns" :isInvitation="selectedField?.value == 'live_shop_invitations_count'" :hosts="hosts" /> 
				</div>
				<div class="col-sm-1">
						<button
						type="button"
						v-on:click="onRemoveCondition()"
						class="btn btn-sm btn-outline-danger">
						{{ $t('audiences.audience_conditions_remove_condition')  }}
				</button>
				</div>
		</div>
</template>