<!-- eslint-disable vue/no-mutating-props -->
<!-- eslint-disable vue/no-mutating-props -->
<script>
import Multiselect from "vue-multiselect";
import {


} from "@/state/helpers";

/**
 * Nav-bar Component
 */
export default {
  data() {
    return {
        submitted:false,
        products:[],
        aux_times: {
          operator: '>'
        },
        operators:[
          {"label" : this.$t("audiences.audience_conditions_bigger_than"), "id" : ">"},
          {"label" : this.$t("audiences.audience_conditions_less_than"), "id" : "<"},
          {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
        ],
        operatorsPrice:[
          {"label" : this.$t("audiences.audience_conditions_bigger_than2"), "id" : ">"},
          {"label" :this.$t("audiences.audience_conditions_less_than2"), "id" : "<"},
          {"label" : this.$t("audiences.audience_conditions_equal"), "id" : "="},
        ],
        dateOperatorsValues: [
        {
          id: "<",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_before_day'),
        },
        {
          id: ">",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_after_day'),
        },
        {
          id: "=",
          label: this.$t('journeys.journey_workflow_components_condition_simple_condition_equal'),
        },
      ],
      };
  },
  components: { Multiselect},
	name: "ProductExpressionBuilder",
  mounted() {
  },
    methods: {
        onProductFiedSelected(item){
          item.term = null;
          item.operator = this.operatorFromItem(item);
			},

      operatorFromItem(item){
        if(item && item.field){
          switch (item.field.type){
            case 'list':
              return 'in';
            case 'products_list':
              return 'in';
            case 'text':
              return '=';
            case 'date':
              return '>';
            case 'product_price':
              return '>';
            case 'boolean':
              return '=';
            case 'product_category':
              return 'in';
            default:
              return '' 
          }
        }
        return '';
      },
			isNodeValid(){
				return this.node.field && this.node.operator && this.node.term;
			},
			onRemoveCondition(index){
				this.query.children.splice(index, 1);
			},
      onAddCondition(){
        this.query.children.push({
          field: null,
          operator:null,
          term: null
        })
      },
      onTimesOperatorSelected(time, isUpdate = false){
        if(!isUpdate && this.times){
          this.times.operator = time.id;
          this.aux_times.operator = time;
        }
        else{
          this.aux_times.operator = this.operators.filter(o=> o.id == time).length > 0 ? this.operators.filter(o=> o.id == time)[0] : null
        }
      },

      onOperatorSelected(item, isUpdate = false){
        if(!isUpdate){
          item.operator = this.aux.operator;
        }else{
          //this.aux.operator = this.operators.filter(o=> o.id == item).length > 0 ? this.operators.filter(o=> o.id == item)[0] : null ;
        }
      },
      
    },
  props: {
    query: Object,
    properties_products: Array,
    times: Object,
    categories: Array,
    hideTimes:Boolean,
    session: Boolean
  },
	emits:["removeCondition"],
  
  watch: {
    times:{
      immediate: true,
      handler(newVal){
        if(newVal){
          this.onTimesOperatorSelected(newVal.operator,true);
        }
      }
    },
    query:{
      immediate: true,
      deep: true,
      handler(newVal){
        if(newVal){
          if(newVal?.children?.length > 0){
            newVal?.children.forEach(c=>{
              if (typeof(c.field) === typeof("any")){
                c.field = this.properties_products.filter(i=> i.column_name == c.field)[0]
              }
            })
          }
        }
      }
    },
  }
}

 </script>

<template>
  <div class="row mb-2">
    <div class="col-sm-10 mb-2">
      <div class="btn-group-horizontal pt-2" role="group">
          <button class="btn btn-sm me-1" :class="query.type == 'and'? 'btn-primary' : 'btn-outline-primary'" @click="query.type= 'and'">
              {{ $t("audiences.audience_conditions_and") }}
          </button>
          <button class="btn btn-sm" :class="query.type == 'or'? 'btn-primary' : 'btn-outline-primary'" @click="query.type= 'or'">
            {{ $t("audiences.audience_conditions_or") }}
          </button>
      </div>
    </div>
    <div class="col-sm-2 pt-2 text-end interact">
      <button class="btn btn-sm btn-link" @click="onAddCondition">
      <i class="bx bx-plus"></i>{{$t("audiences.audience_condition")}}</button>
    </div>
    
		<div v-for="(item, index) in query.children" :key="index" class="pb-2">
      <div class="row">
        <div class="col-sm-3">
          <multiselect
              :options="properties_products"
              v-model="item.field"
              track-by="column_name"
              label="name"
              selectLabel=""
              deselectLabel=""
              size="sm"
              :show-labels="false"
              :allow-empty="false"
              @input="onProductFiedSelected(item)"
          />
        </div>
        <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'list'">
          <multiselect
              :options="item.field.values" 
              v-model="item.term"
              track-by="id"
              label="value"
              selectLabel=""
              deselectLabel=""
              size="sm"
              :multiple="true"
          />
        </div>
          <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'text'">
            <b-form-input class="form-control w-50 me-2" v-model="item.term">
            </b-form-input>
          </div>
          <div class="col-sm-8" v-if="item.field && item.field.type== 'products_list'">
            <multiselect
                :options="products" 
                v-model="item.term"
                track-by="id"
                label="name"
                selectLabel=""
                deselectLabel=""
                size="sm"
                :multiple="true"
            />
          </div>
          <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'product_category'">
            <multiselect
              :options="categories" 
              v-model="item.term"
              track-by="id"
              label="value"
              selectLabel=""
              deselectLabel=""
              size="sm"
              :multiple="true"
          />
          </div>
          <div class="col-sm-8 ps-0" v-if="item.field && item.field.type== 'product_price'">
            <div class="d-inline-flex align-items-baseline w-100">
              <div class="w-50 me-2">
                <div>
                  <multiselect
                    :options="operatorsPrice || []" 
                    v-model="item.aux_operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    class="form control w-100 ms-0"
                    @input="onOperatorSelected(item)"
                    />
                </div>
              </div>
              $ 
              <b-form-input
                type="number"
                v-model="item.term"
                class="w-25 ">
              </b-form-input>
            </div>
            
          </div>
          <div v-if="item.field && item.field.type == 'date'" class="col-sm-8 ps-0">
            <div class="d-inline-flex align-items-baseline w-100">
              <multiselect
                :options="dateOperatorsValues" 
                v-model="item.operator"
                track-by="id"
                label="label"
                selectLabel=""
                deselectLabel=""
                :multiple="false"
                :show-labels="false"
                :allow-empty="false"
                class="w-50 me-2"
              />
              <b-form-input type="date" class="form-control" v-model="item.term" ></b-form-input>
            </div>
          </div>
          <div class="col-sm-8" v-if="item.field && item.field.type== 'any'">
          </div>
        <div class="col-sm-1 text-end interact pt-2" v-if="item.field && query.children.length > 1" v-on:click="onRemoveCondition(index)">
          <i class="bx bx-trash text-danger font-size-18"></i>
        </div>
      </div>
    </div>
    <div class="row pt-0 pe-0" v-if="!hideTimes">
      <div class="col-sm-12 pe-0">
        <hr class="mt-2"/>
        <div class="d-inline-flex align-items-baseline w-100" v-if="!hideTimes">
        <div class="w-25 me-2">
                <div>
                  <multiselect
                    :options="operators || []" 
                    v-model="aux_times.operator"
                    track-by="id"
                    label="label"
                    selectLabel=""
                    deselectLabel=""
                    :multiple="false"
                    class="form control w-100 ms-0"
                    @input="onTimesOperatorSelected"
                    />
                </div>
              </div>

              <div class="w-25 me-2">
                <div class="d-inline-flex align-items-baseline">
                  <b-form-input type="number" class="form-control w-50 me-2" min="1" v-model="times.value" ></b-form-input> <p class="small"> {{times.value == 1 ? session ? $t("personalizations.experience_targeting_session_time") : $t("audiences.audience_conditions_at_least_time") : session ? $t("personalizations.experience_targeting_session_times") : $t("audiences.audience_conditions_at_least_times")}}</p>
                </div>
              </div>
        
      </div>
      </div>
    </div>
	</div>
</template>

<style>
.interact {
  cursor: pointer;
}
</style>